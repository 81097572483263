import React, {useEffect} from 'react';
import {useStore} from "effector-react";
import {$userModel} from '../../models/user-model'
import {
    organizationListMount,
    contextInfo,
    userOrganizations,
    organizationListMountUser
} from "../../models/organization-model";
import {USER_ROLES} from "../../constants/user";


export const UseOrgCarousel = () => {
    const signedUserInfo = useStore($userModel);
    useEffect(() => {
        if (signedUserInfo.$userRole === USER_ROLES.ADMIN || signedUserInfo.$userRole === USER_ROLES.SUPER_ADMIN || signedUserInfo.$userRole === USER_ROLES.BOSS ) {
            const params = {
                length: 30,
            };
            organizationListMount(params);
        } else if (signedUserInfo.$userRole === USER_ROLES.USER) {
            const params = {
                length: 30,
                username: signedUserInfo.$signedUserInfo.data.username
            };
            organizationListMountUser(params);
        } else if (signedUserInfo.$userRole !== null) {
            contextInfo();
        }
    }, [signedUserInfo.$userRole]);
};