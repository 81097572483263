import {useStore} from "effector-react";
import {$userModel, signedUserInfoMount} from "../../models/user-model";
import {useEffect} from "react";

export const useSignedUserInfo = () => {
    const {$signedUserInfo} = useStore($userModel);

    useEffect(() => {
        signedUserInfoMount()
    }, []);

    return {$signedUserInfo}
};