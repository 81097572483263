import {httpGet, httpPost} from "../../api";

export const getUserInfo = () => httpGet({
    url: '/user/'
});

export const UploadFile = (data) => httpPost({
    headers: { "Content-Type": "multipart/form-data" }, url: '/resource', data
});

export const getPositionList = () => httpGet({
    url:'/position/plainlist'
})

export const updateMe = (data) => httpPost({
    url: '/user/me', data
})

export const updatePassword = (data) => httpPost({
    url: '/user/password', data
})
