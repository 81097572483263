import styled, {keyframes} from 'styled-components'
import {Button} from "../../../ui/atoms";

export const OrganizationInfoSection = styled.div`
   background: ${({theme}) => theme.main.themeColor[100]};
  border: 1px solid ${({theme}) => theme.main.semanticColor.basic[300]};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px 24px;
  height: 100%;
`

export const OrganizationInfoTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  text-transform: uppercase;
  margin-bottom: 24px;
`

export const OrganizationInfoList = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 16px;
  @media (max-width: 1440px) {
    grid-gap: 10px;
  }
`

export const OrganizationInfoValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: right;
  color: ${({theme}) => theme.main.semanticColor.basic[800]};
  
  div {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    padding: 4px 8px;
    border: 1px solid ${({theme}) => theme.main.semanticColor.basic[400]};
    background-color: ${({theme}) => theme.main.semanticColor.basic[200]};
    cursor: pointer;                 
    
    span {
        color: ${({theme}) => theme.main.semanticColor.success[500]}
        margin-left: auto
    }
  }
  
   a {
    color: ${({theme}) => theme.main.semanticColor.basic[800]};
    
    &:hover {
        color: unset;
    }
  }
`

export const OrganizationInfoField = styled.div`
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  font-weight: 600;
  color: ${({theme}) => theme.main.semanticColor.basic[600]};
  ${props => props.flex && `display: flex; align-items:center;`}
`

export const DropdownList = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    max-height: 350px;
    
    &::-webkit-scrollbar {
        width: 5px
    }
    
    &::-webkit-scrollbar-thumb{
        background-color: #d3d3d3;
        
    }
       
    div {
       padding: 14px;
       border: 1px solid #EDF1F7;
       color: #222B45;
       font-size: 14px;
       max-width: 365px;
       cursor: pointer;
       &:hover {
       background-color: #f7f7f7;
       }
    } 
`

export const DetailInfoHeading = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
        
    ${OrganizationInfoTitle}{
        margin-bottom: 0
    }
    
    .ant-picker{
    margin-left: auto
    }
    
    button {
        width: unset;
        display: flex;
        align-items: center;
    }
    
    svg {
        margin-right: 10px
    }
`

export const DetailFormContainer = styled.div`
    ${Button} { 
        height: unset;
        padding: 14px 40px;
        font-size: 16px
    }
`

export const DetailTab = styled.div`
  font-weight: bold;
  cursor: pointer;
  color: ${({active}) =>  active ? '#222B45' : '#BCBCBC'};
  margin-bottom: 36px;
  line-height: 16px;
  text-transform: uppercase;

  &:first-child{
    padding-right: 20px;
    border-right: 1px solid #BCBCBC;
  }
  
  &:last-child{
    padding-left: 20px;
  }
`

export const UserListInfoHeading = styled.div`
    padding: 24px 0;
    
    @media (max-width: 1440px) {
      padding: 18px;
    }
    
    h4 {
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        color: ${({theme}) => theme.main.semanticColor.basic[800]};
        text-transform: uppercase;
        margin-bottom: 0;
        white-space: nowrap;
        
        @media (max-width: 1440px) {
          font-size: 20px;
        }
    }
    
    input {
        padding: 11px 15px !important;
        background: none;
    }
    
    .ant-select-selector{
        padding: 8px 15px !important;
        background: none !important;
        
        input {
            padding:0 !important;
        }
    }
`

export const UserListInfoGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    padding: 24px;
    overflow-y: auto;
    max-height: 70vh;
    min-height: 70vh;
    scrollbar-color: ${({theme}) => theme.main.semanticColor.basic[600]} #fff;
    scrollbar-width: thin;
    
    @media (max-width: 1440px) {
      padding: 12px;
    }
    
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${({theme}) => theme.main.semanticColor.basic[600]};
    }
`

export const UserListInfo = styled.div`
     display: grid;
    grid-template-columns: 180px 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    
    @media (max-width: 1440px) {
      grid-row-gap: 10px;
    }
`

export const UserListInfoItem = styled.div`
    display: grid;
    grid-template-columns: 67px 1fr;
    grid-column-gap: 50px;
`

export const UserListInfoField = styled.div`
    color: ${({theme}) => theme.main.semanticColor.basic[600]}
`

export const UserListInfoValue = styled.div`
    color: ${({theme}) => theme.main.semanticColor.basic[800]}
`

export const UserAvatar = styled.div`
    overflow: hidden;
    position: relative;
    border: 1px solid ${({theme}) => theme.main.semanticColor.basic[300]};
    width: 67px;
    height: 67px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        
    // span {
    //     display: inline-block;
    //     position: absolute;
    //     width: 13px;
    //     height: 13px;
    //     border: 2px solid ${({theme}) => theme.main.themeColor[100]}
    //     background-color: ${({theme}) => theme.main.semanticColor.success[500]};
    //     right: 3px;
    //     bottom: 1px;
    //     border-radius: 50%
    // }
    
    img{
        object-fit: contain;
        display: block;
        max-width:100%;
        height: auto;
    }
`
const slideDown = keyframes`
 0% {
        opacity: 0;
        transform: translateY(-60px);
    } 
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`

export const LengthSectionTitle = styled.div`
  display: flex;
  align-items: center;
  color: #3366FF;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 60px;
  padding: 8px 24px;
  min-width: 400px;
  
  div {
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    position: relative;
  }
  
  svg{
       //bottom: -15px;
    color: #8F9BB3;
    transition: .3s;
    transform: ${props => !props.visible ? 'scaleY(1)' : 'scaleY(-1)'};
  }
`;

export const LengthSectionWrap = styled.div`
  display: ${({visible}) => visible ? 'flex' : 'none'};
  border: 1px solid #EDF1F7;
  background: #ffffff;
  align-items: center;
  animation: ${slideDown} .25s;  
`;


export const InfoResultBlock = styled.div`
  background-color: ${({theme}) => theme.main.themeColor[100]};
  //scrollbar-color: #C4CFDD #fff;
  //scrollbar-width: thin;
  //max-height: 400px;
  //overflow: auto;
  //  
  //&::-webkit-scrollbar {
  //    width: 9px;
  //    height: 9px;
  //    opacity: .45;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  background-color: #C4CFDD;
  //  border-radius: 8px;
  //  border: 1px solid #8F9BB3;
  //}
`

export const InfoValue = styled.div`
  display: flex;
  align-items: center;
  
  span {
    display: block;
    min-width: 184px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
  }
`

export const Infos = styled.div`
 
`

export const InfoSectionTable = styled('table')`
  width: 100%;
  border: 1px solid #EDF1F7;
  border-collapse: collapse;
  
  tbody tr:nth-child(even){
    background-color: #F6F7F9;
  }
  
  td {
    text-align: center;
    border: 1px solid #EDF1F7;
    min-width: 184px;
    padding: 10px 0;
    color: #222B45;
  }
`

export const TitleTd = styled('td')`
  padding: 10px 24px !important;
  min-width: 400px !important;
  text-align: left !important;
  color: #8F9BB3 !important
`
