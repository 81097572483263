import React, {Fragment, useEffect, useState} from 'react'
import {
    UserAvatar,
    UserListInfo,
    UserListInfoField,
    UserListInfoValue,
    UserListInfoGrid,
    UserListInfoHeading,
    UserListInfoItem
} from "../atoms";
import {Col, Empty, Row} from "antd";
import {InputSystem, Spinner} from "../../../ui/molecules";
import {SelectSystem} from "../../../ui/molecules/select";
import {ImageLoad} from "../../../components/download";
import {TableBordered} from "../../../ui/atoms";
import {RemoveDuplicate} from "../../../helpers";
import {useTranslation} from "react-i18next";


export const ModalOrgUserListView = (props) => {
    const {search, setSearch, loading, userData} = props
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (!userData.length) {
            setDisabled(true)
        }
    }, [userData])

    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(1);
    const {t} = useTranslation()

    function changePagination(page) {
        setPage(page);
        setStart(((page - 1) * pageSize) - (page === 1 ? 0 : 1))
    }

    function changeShowSize(current, size) {
        setPageSize(size)
    }

    const columns = [
        {
            title: t('photo'),
            dataIndex: 'image',
            key: 'image',
            width: 60,
            render: (id, data) => (<UserAvatar>
                <ImageLoad id={data.image} show={true}/>
                <span/>
            </UserAvatar>)
        },
        {
            title: t('full-name'),
            dataIndex: 'name',
            key: 'name',
            render: (id, data) => (<div>{data.name}</div>)
        },
        {
            title: t('position'),
            dataIndex: 'role',
            key: 'role',
            width: 80,
            render: (id, data) => (<div>{data.role}</div>)
        },
        {
            title: t('was-online'),
            dataIndex: 'online',
            key: 'online',
            width: 120,
            render: (id, data) => (<div>{data.online}</div>)
        },
        {
            title: t('phone'),
            dataIndex: 'phone',
            key: 'phone',
            width: 80,
            render: (id, data) => (<div>{data.phone}</div>)
        },
        {
            title: t('directions'),
            dataIndex: 'sector',
            key: 'sector',
            render: (id, data) => (
                <Fragment>{data.sector !== null ? RemoveDuplicate(data.sector, 'id').map((item, idx) => (
                    <div key={idx}>{item.name}</div>)) : t('absent')}</Fragment>)
        },
        {
            title: t('driving'),
            dataIndex: 'subSector',
            key: 'subSector',
            render: (id, data) => (
                <Fragment>{data.subSector && data.subSector.length > 0 && data.subSector.map((item, idx) => (
                    <div key={idx}>{item}</div>))}</Fragment>)
        },
        {
            title: t('administrative-division'),
            dataIndex: 'division',
            key: 'division',
            render: (id, data) => (
                <Fragment>{data.division && data.division.length > 0 && data.division.map((item, idx) => (
                    <div key={idx}>{item}</div>))}</Fragment>)
        },
    ];

    return (
        <Fragment>
            <UserListInfoHeading>
                <Row type='flex' gutter={24} style={{alignItems: 'center'}}>
                    <Col span={8}>
                        <h4>
                            ИНФОРМАЦИЯ о сотрудниках
                        </h4>
                    </Col>
                    <Col span={16}>
                        <InputSystem
                            type='search'
                            value={search}
                            change={setSearch}
                            placeholder='Найти сотрудника'
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </UserListInfoHeading>
            <TableBordered
                bordered
                loading={loading}
                columns={columns}
                dataSource={userData}
                pagination={
                    {
                        pageSize: pageSize,
                        pageSizeOptions: ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
                        total: userData.length,
                        onChange: changePagination,
                        onShowSizeChange: changeShowSize,
                        current: page,
                        showSizeChanger: true
                    }
                }
            />
        </Fragment>
    )
}

export const ModalAllUserListView = (props) => {
    const {search, setSearch, loading, userData} = props
    const [filterValue, setFilterValue] = useState(1)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (!userData.length) {
            setDisabled(true)
        }
    }, [userData])

    const selectData = [
        {
            value: 1,
            label: 'Только онлайн'
        },
        {
            value: 2,
            label: 'Только Оффлайн'
        },
        {
            value: 3,
            label: 'Показать всех'
        },
    ]


    return (
        <Fragment>
            <UserListInfoHeading>
                <Row type='flex' gutter={24} style={{alignItems: 'center'}}>
                    <Col span={8}>
                        <h4>
                            ИНФОРМАЦИЯ о сотрудниках
                        </h4>
                    </Col>
                    <Col span={12}>
                        <InputSystem
                            type='search'
                            value={search}
                            change={setSearch}
                            placeholder='Найти сотрудника'
                            disabled={disabled}
                        />
                    </Col>
                    <Col span={4}>
                        <SelectSystem
                            value={filterValue}
                            change={setFilterValue}
                            options={selectData}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </UserListInfoHeading>
            <UserListInfoGrid>
                {!loading
                    ? <Fragment>
                        {
                            userData.length
                                ? userData.map(user => {
                                    return (
                                        <UserListInfoItem key={user.username}>
                                            <div>
                                                <UserAvatar>
                                                    <ImageLoad id={user.image} show={true}/>
                                                    {/*<span></span>*/}
                                                </UserAvatar>
                                            </div>
                                            <UserListInfo>
                                                <UserListInfoField>Ф.И.О</UserListInfoField>
                                                <UserListInfoValue>{`${user.sname} ${user.name}`}</UserListInfoValue>
                                                <UserListInfoField>Должность</UserListInfoField>
                                                <UserListInfoValue>{user.position && user.position.title}</UserListInfoValue>
                                                <UserListInfoField>Был в сети</UserListInfoField>
                                                <UserListInfoValue>Онлайн</UserListInfoValue>
                                                <UserListInfoField>Телефон</UserListInfoField>
                                                <UserListInfoValue>{user.phone}</UserListInfoValue>
                                                <UserListInfoField>Название участка</UserListInfoField>
                                                <UserListInfoValue>Коканд - Андижан-I, Маргилан-Фергана-I, Ахунбабаева
                                                    -
                                                    Разъезд
                                                    38 км ,
                                                    Маргилан
                                                    - Ахунбабаева
                                                    II-соед. путь
                                                </UserListInfoValue>
                                                <UserListInfoField>Административное деление от и
                                                    до</UserListInfoField>
                                                <UserListInfoValue>Кок.-Анд-I. 259 км - 272 км, Марг.-Ферг.-I. 1а,1б
                                                    км -
                                                    9 км,
                                                    Ахун.- Рзд.38 км 1 км -38 км пк6,
                                                    Марг.-Ахунбабаева II-соед. путь 260 км - 264 км
                                                </UserListInfoValue>
                                            </UserListInfo>
                                        </UserListInfoItem>
                                    )
                                })
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        }
                    </Fragment>
                    : <Spinner/>
                }
            </UserListInfoGrid>
        </Fragment>
    )
}
