import React from 'react'
import {useOrganizationInfo} from "../../../hooks/info";
import {InfoItem, InfoTitle, InfoWrapper, UserCountButton} from "../atoms";
import {Trans, useTranslation} from "react-i18next";
import {orgInfo} from "../helpers";
import {SectionWithSpinner} from "../../../components/section-with-spinner";
import {useStore} from "effector-react";
import {$report} from "../../../models/report-model";
import {UserListModal} from "../../../ui/molecules";
import {$infoModel} from "../../../models/info-model";
import {ModalOrgUserListView} from "./user-list-modal";
import {isAdmin} from "../../../hooks/info/helper";
import {$userModel} from "../../../models/user-model";

export const OrganizationInfo = () => {
    const {
        getOrgPermission,
        setStatusModal,
        statusModal,
        setSearch,
        search
    } = useOrganizationInfo()
    const {$orgUserList} = useStore($infoModel)
    const {$userRole} = useStore($userModel)
    const {t} = useTranslation()

    const {
        $organizationInfo,
    } = useStore($report)

    const handleClick = () => {
        setStatusModal(true)
        getOrgPermission()
    }

    const renderValue = (item) => {
        switch (item.key) {
            case 'totalLength':
                return $organizationInfo.data[item.key] ? `${$organizationInfo.data[item.key] / 1000} ${t('km')}` : ''
            case 'totalUsers':
                return isAdmin($userRole)
                    ? (<UserCountButton onClick={handleClick}>{$organizationInfo.data[item.key]}</UserCountButton>)
                    : $organizationInfo.data[item.key]
            default:
                return $organizationInfo.data[item.key2] !== undefined && $organizationInfo.data[item.key] !== undefined
                    ? (<>{`${$organizationInfo.data[item.key]} (${$organizationInfo.data[item.key2] / 1000} ${t('km')})`}</>)
                    : $organizationInfo.data[item.key] !== undefined ? $organizationInfo.data[item.key] : 0
        }
    }

    return (
        <>
            <UserListModal
                statusModal={statusModal}
                setStatusModal={setStatusModal}
                component={
                    <ModalOrgUserListView
                        search={search}
                        setSearch={setSearch}
                        userData={$orgUserList.data}
                        loading={$orgUserList.loading}
                    />}
            />
            <SectionWithSpinner loading={$organizationInfo.loading}>
                <InfoTitle>
                    <Trans i18nKey="information-about" values={{name: $organizationInfo.data.name}}/>
                </InfoTitle>
                <InfoWrapper>
                    {
                        orgInfo.map(item => (
                            <InfoItem key={item.id}>
                                <InfoItem.Title>
                                    <Trans i18nKey={item.title}/>
                                </InfoItem.Title>
                                <InfoItem.Value>
                                    {
                                        renderValue(item)
                                    }
                                </InfoItem.Value>
                            </InfoItem>
                        ))
                    }
                </InfoWrapper>
            </SectionWithSpinner>
        </>
    )
}