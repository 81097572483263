import {createEvent} from "effector";
import {
    fetchContextInfo,
    fetchLogList,
    fetchOrganizationList,
    fetchOrganizationInfo,
    fetchOrganizationSectorList,
    fetchSubSectorList,
    fetchSectorInfo,
    fetchCategoriesList,
    fetchAttributeList,
    fetchEntrySector,
    fetchSectorList,
    fetchUserList,
    fetchOrganizationUserList, fetchAddLogList,
} from "./effects";

export const contextInfo = createEvent();
export const organizationListMount = createEvent();
export const organizationInfoMount = createEvent();
export const showOrganizationInfo = createEvent();
export const organizationSectorListMount = createEvent();
export const subSectorListMount = createEvent();
export const sectorInfoMount = createEvent();
export const sectorInfoEvent = createEvent();
export const categoriesListMount = createEvent();
export const attributeList = createEvent();
export const entrySection = createEvent();
export const logList = createEvent();
export const addLogList = createEvent();
export const sectorListMount = createEvent();
export const userListMount = createEvent();
export const organizationUserListMount = createEvent();
export const timeEvent = createEvent();

contextInfo.watch(fetchContextInfo);
organizationListMount.watch(fetchOrganizationList);
organizationInfoMount.watch(fetchOrganizationInfo);
organizationSectorListMount.watch(fetchOrganizationSectorList);
subSectorListMount.watch(fetchSubSectorList);
sectorInfoMount.watch(fetchSectorInfo);
categoriesListMount.watch(fetchCategoriesList);
attributeList.watch(fetchAttributeList);
entrySection.watch(fetchEntrySector);
logList.watch(fetchLogList);
addLogList.watch(fetchAddLogList);
sectorListMount.watch(fetchSectorList);
userListMount.watch(fetchUserList);
organizationUserListMount.watch(fetchOrganizationUserList);
