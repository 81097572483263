import React, {useState, useEffect} from 'react'
import ReactApexChart from 'react-apexcharts'
import {useUrlParams} from '../../../hooks/common'
import {useHistory} from 'react-router-dom'
import {sectorInfoMount, $isDataPending} from "../model";
import {useStore} from "effector-react";
import {convertTo} from "../../../helpers";

const {Math} = convertTo()

export const Monitoring = () => {
    const {location} = useHistory()
    const {subSectorId, sectorId} = useUrlParams(location)
    const {$sectorInfo} = useStore($isDataPending)
    const [data, setData] = useState([])
    const [category, setCategory] = useState([])
    const [title, setTitle] = useState('')

    useEffect(() => {
        let id = ''
        if (subSectorId) {
            id = subSectorId
        }
        if (id.length > 0) {
            sectorInfoMount(id);
        }

    }, [subSectorId])

    useEffect(() => {
        const sectorInfo = $sectorInfo.data
        if (Object.values(sectorInfo).length > 0) {
            let km = [];
            let tmp = []
            setTitle(sectorInfo.name)
            const start = sectorInfo.startPoint
            const end = Math.floor10((sectorInfo.startPoint * 1000 + sectorInfo.length + sectorInfo.countdownPoint) / 1000)
            for (let i = start; i < end + 1; i++) {
                km.push(`${i} km`)
                let plusOrMinus = Math.floor(Math.random() * 100);
                tmp.push(plusOrMinus)
            }
            setData(tmp)
            setCategory(km)
        }
    }, [$sectorInfo.data])


    const series = [{
        name: title,
        data: data
    }]
    const options = {
        chart: {
            height: 350,
            type: 'area'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'category',
            categories: category
        },
    }

    return (
        <div style={{padding: '10px', backgroundColor: '#fff'}}>
            <ReactApexChart
                options={options} series={series} type="area" height={350}
            />
        </div>
    )
}