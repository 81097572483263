import {createEffect} from "effector";
import {getPositionList, getUserInfo} from "../api";

export const fetchUserInfo = createEffect({
    handler: getUserInfo
});

export const fetchPositionList = createEffect({
    handler: getPositionList
})
