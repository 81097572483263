import {httpPost, httpGet} from "../../api";

export const getToken = (data) => httpPost({
    url: 'auth/token', data
});

export const getOTP = (username) => httpGet({
    url: `/password/${username}`
})

export const restorePassword = ({username, data}) => httpPost({
    url: `/password/${username}`, data
})
