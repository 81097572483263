import styled from 'styled-components';

export const MainButton = styled.button`
  display: none;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin-top: 2px;
  svg {
    width: 24px !important;
    height: 24px !important;
  }
  
  @media (max-width: 1280px) {
    display: flex;
  }
`;
