export const Journal = [
  {
    id: 1,
    name: 'Boss',
    job: 'Director',
    date: '12 Сентября 2019 в 14:22',
    category: 'Путевые и сигнальные знаки',
    attribute: 'Указатели наивысшего горизонта вод и макс. высота волны',
    to: 0,
    from: 100,
    description: 'Ремонт',
  },
  {
    id: 2,
    name: 'Boss',
    job: 'Director',
    date: '12 Сентября 2019 в 14:22',
    category: 'Путевые и сигнальные знаки',
    attribute: 'Указатели наивысшего горизонта вод и макс. высота волны',
    to: 0,
    from: 100,
    description: 'Ремонт',
  },
  {
    id: 3,
    name: 'Boss',
    job: 'Director',
    date: '12 Сентября 2019 в 14:22',
    category: 'Путевые и сигнальные знаки',
    attribute: 'Указатели наивысшего горизонта вод и макс. высота волны',
    to: 0,
    from: 100,
    description: 'Ремонт',
  },
  {
    id: 4,
    name: 'Boss',
    job: 'Director',
    date: '12 Сентября 2019 в 14:22',
    category: 'Путевые и сигнальные знаки',
    attribute: 'Указатели наивысшего горизонта вод и макс. высота волны',
    to: 0,
    from: 100,
    description: 'Ремонт',
  }
];


export const styleMap = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#929292"
      },
      {
        "weight": 1
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3faaff"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  }
];