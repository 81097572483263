import {useUrlParams} from "../common";
import {useEffect} from "react";
import {useStore} from "effector-react";
import {$isDataPending} from "../../models/categories-attr-model";
import {useHistory, useLocation} from "react-router-dom";
import {$organization} from "../../models/organization-model";


export const RedirectHooks = () => {
    const history = useHistory();
    const location = useLocation();
    const organization = useStore($organization);


    const isDataPending = useStore($isDataPending);


    const {categoryId, organizationId, info, urlParams} = useUrlParams(location, ['organizationId', 'sectorId', 'subSectorId', 'info']);
    useEffect(() => {
        if (organization.$organizationsList.data.length === 1 && isDataPending.$dashboardCategoriesList.data && isDataPending.$dashboardCategoriesList.data.data.length > 0) {
            if (!organizationId) {
                if (categoryId === null) {
                    history.push(`/?${info ? `info&` : ''}organizationId=${organization.$organizationsList.data[0].id}&categoryId=${isDataPending.$dashboardCategoriesList.data.data[0].id}${urlParams ? `&${urlParams}` : ''}`)
                } else {
                    history.push(`/?${info ? `info&` : ''}organizationId=${organization.$organizationsList.data[0].id}${urlParams ? `&${urlParams}` : ''}`)
                }
            } else {
                if (categoryId === null) {
                    history.push(`/?${info ? `info&` : ''}organizationId=${organizationId}&categoryId=${isDataPending.$dashboardCategoriesList.data.data[0].id}${urlParams ? `&${urlParams}` : ''}`)
                }
            }

        } else if(organization.$organizationsList.data && organization.$organizationsList.data.length > 0 && isDataPending.$dashboardCategoriesList.data && isDataPending.$dashboardCategoriesList.data.data && isDataPending.$dashboardCategoriesList.data.data.length > 0){
            if (categoryId === null) {
                history.push(`/?${info ? `info&` : ''}organizationId=${organization.$organizationsList.data[0].id}&categoryId=${isDataPending.$dashboardCategoriesList.data.data[0].id}${urlParams ? `&${urlParams}` : ''}`)
            }
        }
    }, [organization.$organizationsList.data, organizationId, categoryId]);

}