import styled from "styled-components";

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  text-align: center;
  background: none !important;
  border: 0 !important;
    height: 100%;
    .anticon-loading {
        width: unset !important;
        height: unset !important;
    }
`
