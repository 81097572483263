import React, {useEffect, useState} from 'react';
import {GetRecource} from "../../../components/download/api";
import {Spinner} from "../../../ui/molecules";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import {GetImgStyle} from "../atoms";


export const GetImg = (props) => {
    const {
        id
    } = props;
    const [imgData, setImgData] = useState(false);

    useEffect(() => {
        if (id !== undefined) {
            GetRecource(id)
                .then(response => {
                        if (response.status === 200) {
                            const data = response.data;
                            const urlCreator = window.URL || window.webkitURL;
                            const imageUrl = urlCreator.createObjectURL(data);
                            setImgData(imageUrl);
                        }
                    }
                );
        }

    }, [id]);

    return imgData ?
        <GetImgStyle><img style={{maxHeight: 300}} src={imgData} alt="avatar" /></GetImgStyle> :
        <Spinner icon={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
};