import {createEffect} from "effector";
import {getPoints} from "../../api/google-maps-api";
import {getAttrInfoApi} from "../../api/entry-attr-api";

export const fetchGoogleMapPoints = createEffect({
    handler: getPoints
});

export const fetchGetAttrSector = createEffect({
    handler: getAttrInfoApi
});