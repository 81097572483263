import React from 'react'
import {useAllOrganizationInfo} from "../../../hooks/info";
import {useStore} from "effector-react";
import {$report} from "../../../models/report-model";
import {SectionWithSpinner} from "../../../components/section-with-spinner";
import {Trans, useTranslation} from "react-i18next";
import {InfoItem, InfoTitle, InfoWrapper} from "../atoms";
import {allOrgInfo} from "../helpers";

export const AllOrganizationInfo = () => {
  useAllOrganizationInfo()
  const {$allOrgInfo} = useStore($report)

    const {t} = useTranslation()

  return (
    <SectionWithSpinner loading={$allOrgInfo.loading}>
      <InfoTitle>
        <Trans i18nKey="information-about" values={{name: 'ПЧ'}}/>
      </InfoTitle>
      <InfoWrapper>
        {
          allOrgInfo.map(item => (
            <InfoItem key={item.id}>
              <InfoItem.Title>
                  {t(item.title)}
              </InfoItem.Title>
              <InfoItem.Value>
                {
                  Object.values($allOrgInfo.data).length > 0
                  && (
                    <>
                      {
                        item.key2
                          ? `${$allOrgInfo.data[item.key]} (${$allOrgInfo.data[item.key2] / 1000} км)`
                          : item.key === 'totalUsers' ? $allOrgInfo.data[item.key] : `${$allOrgInfo.data[item.key] / 1000} км`
                      }
                    </>
                  )
                }
              </InfoItem.Value>
            </InfoItem>
          ))
        }
      </InfoWrapper>
    </SectionWithSpinner>
  )
}