import React from 'react'
import {SpinnerWrapper} from "../atoms";
import {Spin} from "antd";

export const Spinner = (props) => {
    const {size, icon, delay} = props
    return (
          <SpinnerWrapper>
              <Spin
                    size={size}
                    indicator={icon}
                    delay={delay}
              />
          </SpinnerWrapper>
    )
}
