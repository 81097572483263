import React from "react";
import {OrganizationListCarousel} from "../molecules";
// import {contextInfo} from "../model/events";

export const DashboardHead = () => {

  // useEffect(() => {
  //   // contextInfo();
  // }, []);

  return (
      <OrganizationListCarousel/>
  );
};
