import React, {useEffect, useState} from "react";
import {Row, Col, Breadcrumb, Menu} from "antd";
import {LinkDashboard, NoBorderButton, NoBorderDatePicker, SyncButton} from "../atoms";
import {ListSvg, MapSvg, SyncSvg} from "../../../media/svg";
import {InfoCircleOutlined} from '@ant-design/icons';
import {NavLink, useLocation} from 'react-router-dom'
import {useUrlParams} from "../../../hooks/common";
import moment from "moment";
import {useStore} from "effector-react";
import {$isDataPending} from "../model";
import {timeEvent} from "../model/events";
import {useUserRole} from "../../../hooks/common";
import {USER_ROLES} from "../../../constants/user";
import {$organization} from "../../../models/organization-model";
import {useTranslation} from "react-i18next";
import {$googlePoints, googlePointPageMount} from "../../../models/google-map";
import {selectEvent} from "../../../models/report-model";


export const UnderMainCarousel = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const isDataPending = useStore($isDataPending);
    const organization = useStore($organization);
    const googlePoints = useStore($googlePoints);
    const [sectorsData, setSectorsData] = useState(undefined);
    const [subSectorsData, setSubSectorsData] = useState(undefined);

    const {tabType, urlParams, organizationId, sectorId, subSectorId} = useUrlParams(location, ['info', 'tabType', 'sectorId', 'subSectorId', 'organizationId']);

    const {$userRole} = useUserRole();

    useEffect(() => {
        if (sectorId && organization.$organizationsSectorsList.data && organization.$organizationsSectorsList.data.length > 0) {
            organization.$organizationsSectorsList.data.filter(item => item.id === Number(sectorId) && setSectorsData(item))
        }
    }, [organization.$organizationsSectorsList.data, sectorId]);

    useEffect(() => {
        if (subSectorId && sectorsData && sectorsData.subSectors && sectorsData.subSectors.length > 0){
            sectorsData.subSectors.filter( item => item.id === Number(subSectorId) && setSubSectorsData(item));
        }
    }, [subSectorId, sectorsData]);

    const sectorMenu = (data) => {
        return (
            <Menu>
                {
                    data && data.length > 0 &&
                    data.map((item, i) =>
                        <Menu.Item key={i}>
                            <NavLink
                                to={`/?sectorId=${item.id}&organizationId=${organizationId}${tabType ? '&tabType=' + tabType : ''}&${urlParams}`}
                            >
                                {item.label}
                            </NavLink>
                        </Menu.Item>
                    )
                }
            </Menu>
        )
    };
    const subSectorMenu = (data) => {
        return (
            <Menu>
                {
                    data && data.length > 0 &&
                    data.map((item, i) =>
                        <Menu.Item key={i}>
                            <NavLink
                                to={`/?subSectorId=${item.id}&sectorId=${sectorId}&organizationId=${organizationId}${tabType ? '&tabType=' + tabType : ''}&${urlParams}`}
                            >
                                {item.label}
                            </NavLink>
                        </Menu.Item>
                    )
                }
            </Menu>
        )
    };

    const onChange = (date) => {
        timeEvent(date.valueOf())
    };

    const syncPoints = () => {
        const data = {
            ne: {
                lat: 100,
                long: 100
            },
            sw: {
                lat: 0,
                long: 0
            }
        };
        googlePointPageMount(data);
    }


    return (
        <Row>
            <Col span={0} xl={10}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to='/'>{t('main')}</NavLink>
                    </Breadcrumb.Item>
                    {
                        organizationId &&
                        <Breadcrumb.Item
                            overlay={sectorMenu(organization.$organizationsSectorsList.data)}
                        >
                            {
                                organization.$organizationsList.data && organization.$organizationsList.data.length > 0 ?
                                    organization.$organizationsList.data.filter(item => item.id === Number(organizationId))[0].label : organizationId
                            }
                        </Breadcrumb.Item>
                    }
                    {
                        sectorId && sectorsData ?
                            <Breadcrumb.Item
                                overlay={subSectorMenu(sectorsData.subSectors)}>
                                {

                                    sectorsData.label
                                }
                            </Breadcrumb.Item> :
                            <Breadcrumb.Item>
                                {sectorId}
                            </Breadcrumb.Item>
                    }
                    {
                        organizationId && sectorId && subSectorId &&
                        <Breadcrumb.Item>
                            {
                                subSectorsData && subSectorsData.label ? subSectorsData.label : subSectorId
                            }
                        </Breadcrumb.Item>
                    }
                </Breadcrumb>
            </Col>
            <Col span={24} xl={{span: 6, offset: 2}} xxl={{span: 7, offset: 2}}>
                <Row style={{justifyContent: 'flex-end'}}>
                    <Col style={{textAlign: 'right', padding: '0 12px'}}>
                        <SyncButton disabled={googlePoints.loading} onClick={syncPoints}>
                            <SyncSvg/>
                        </SyncButton>
                        <LinkDashboard
                            to={{
                                pathname: '/',
                                search: `tabType=map${location.search ? '&' + location.search.replace('?', '') : ''}`,
                                state: {fromDashboard: true}
                            }}
                            isActive={() => !tabType || tabType === 'map'}
                        >
                            <MapSvg/>{t('map')}
                        </LinkDashboard>
                    </Col>
                    <Col span={'auto'} style={{textAlign: 'right', padding: '0 12px'}}>
                        <LinkDashboard
                            to={{
                                pathname: '/',
                                search: `tabType=list${location.search ? '&' + location.search.replace('?', '') : ''}`,
                                state: {fromDashboard: true}
                            }}
                            isActive={() => tabType === 'list'}
                        >
                            <ListSvg/>{t('list')}
                        </LinkDashboard>
                    </Col>
                    <Col span={'auto'} style={{textAlign: 'right', padding: '0 0 0 12px'}}>
                        <NoBorderDatePicker
                            format='DD/MM/YYYY'
                            value={moment(isDataPending.$timeStore)}
                            onChange={onChange}
                        />
                    </Col>
                    <Col span='auto' xl={0}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {$userRole !== USER_ROLES.MANAGER &&
                            <NavLink
                                to={{
                                    pathname: '/',
                                    search: `info${location.search ? '&' + location.search.replace('?', '') : ''}`
                                }}>
                                <NoBorderButton icon={<InfoCircleOutlined/>} style={{textAlign: 'right'}}>
                                    {t('info')}
                                </NoBorderButton>
                            </NavLink>
                            }
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col span={0} xl={6} xxl={5}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {$userRole !== USER_ROLES.MANAGER &&
                    <NavLink
                        onClick={() => selectEvent({
                            mainPath: `${location.search ? '&' + location.search.replace('?', '') : ''}`
                        })}
                        to={{
                            pathname: '/',
                            search: `info${location.search ? '&' + location.search.replace('?', '') : ''}`
                        }}>
                        <NoBorderButton icon={<InfoCircleOutlined/>} style={{textAlign: 'right'}}>
                            {t('info')}
                        </NoBorderButton>
                    </NavLink>
                    }
                </div>
            </Col>
        </Row>
    );
};