import React from 'react'
import {ReportCommonSummary, ReportDetailSummary} from "../moleculas";
import {SectionWithSpinner} from "../../../components/section-with-spinner";
import {useStore} from "effector-react";
import {$report} from "../../../models/report-model";
import {Empty} from "antd";

export const ReportSummary = () => {
    const {$selectedIds} = useStore($report)
    const {type} = $selectedIds

    const renderReport = () => {
        switch (type) {
            case 'common':
                return <ReportCommonSummary/>
            case 'detail':
                return <ReportDetailSummary />
            default :
                return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
    }

    return (
        <SectionWithSpinner loading={false}>
            {renderReport()}
        </SectionWithSpinner>
    )
}