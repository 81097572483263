import {Row, Col, Button} from "antd";
import {useStore} from "effector-react";
import React from "react";
import {useLocation} from "react-router-dom";
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {CarouselUI, CarouselNavLink} from "../atoms";
import {$organization} from "../../../models/organization-model";
import {$userModel} from "../../../models/user-model";
import {USER_ROLES} from '../../../constants/user'
import {useUrlParams} from "../../../hooks/common";
import {clearSelected} from "../../../models/report-model";


export const OrganizationListCarousel = () => {
    const location = useLocation();

    const {$userRole} = useStore($userModel);
    const organization = useStore($organization);
    const {organizationId, info} = useUrlParams(location, ['organizationId', 'sectorId', 'subSectorId', 'info']);


    return (
        <Row>
            <Col span={24}>
                <CarouselUI
                    className='org-class'
                    enableKeyboardControls
                    slidesToShow={6}
                    wrapAround={true}
                    renderCenterLeftControls={
                        ({previousSlide}) => organization.$organizationsList.data && organization.$organizationsList.data.length > 6 &&
                            <Button icon={<LeftOutlined/>} onClick={previousSlide}/>
                    }
                    renderCenterRightControls={
                        ({nextSlide}) => organization.$organizationsList.data && organization.$organizationsList.data.length > 6 &&
                            <Button icon={<RightOutlined/>} onClick={nextSlide}/>
                    }
                >
                    {
                        organization.$organizationsList.data &&
                        organization.$organizationsList.data.map(item =>
                            <CarouselNavLink
                                key={item.id}
                                onClick={() => clearSelected()}
                                to={{
                                    pathname: '/',
                                    search: $userRole !== null && $userRole !== USER_ROLES.USER && $userRole !== USER_ROLES.EMPLOYEE
                                        ? Number(organizationId) === Number(item.id)
                                            ? `${info ? `info` : ''}`
                                            : `${info ? `info&` : ''}organizationId=${item.id}&categoryId=1`
                                        : `${info ? `info&` : ''}organizationId=${item.id}&categoryId=1`,
                                    state: {fromDashboard: true}
                                }}
                                isActive={() => Number(organizationId) === Number(item.id)}
                            >
                                          <span>
                                              {item.label}
                                          </span>

                            </CarouselNavLink>
                        )
                    }
                </CarouselUI>
            </Col>
        </Row>
    );
};