import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

export const LinkCustom = styled(Link)`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #8f9bb3;
`;
export const MainLink = styled(NavLink)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #222b45;
  height: 64px;
  border-bottom: 1px solid #edf1f7;
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
  padding: 0 18px 0 14px;
  transition: 0.3s;
  border-left: 4px solid transparent;
  svg{
    margin-right: 15px;
  }
  &:hover,
  &:focus,
  &.active {
    background: #3366ff14;
    color: #3366ff;
    border-left-color: #3366ff;
  }
  @media (max-width: 1440px) {
    height: 60px;
    font-size: 13px;

    svg {
      transform: scale(.9);
    }
  }
`;
