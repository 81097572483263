import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  border: 1px solid #EDF1F7;
  border-radius: 8px;
  position: relative;
`

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  z-index: 3;
  background-color: rgba(255,255,255 .7);
  display: flex;
  flex-direction: column;
`