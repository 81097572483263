import styled from "styled-components";
import { Table } from "antd";

export const CustomTable = styled(Table)`
  background: white;
  &.popap{
    .ant{
      &-table{
        thead{
          & > tr{
            & > th{
              background: #ffffff;
              font-weight: bold;
              font-size: 12px;
              line-height: 16px;
              color: #8992A3;
              text-transform: uppercase;
            }
          }
        }
        tbody{
          & > tr{
            & > td{
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              color: #222B45;
              padding: 7px 16px;
            }
          }
        }
      }
    }
  }
  
  .ant-table-thead {
    display: none !important;
  }
  &.second-color{
    .ant-table-tbody{
      tr{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #222B45;
        &:nth-child(even){
          background: rgba(143, 155, 179, 0.08);
        }
      }
    }
  }
`;

export const ContentTitle = styled.div`
  padding: 25px 15px;
  font-size: 16px;
  color: #8F9BB3;
  font-style: normal;
  font-weight: bold;
  //font-size: 18px;
  line-height: 24px;
`;
