import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { CaptureSvg, PersonSvg } from "../../../media";
import { AvatarUploadLabel } from "../atoms";
import { UploadFile } from "../api";
import { ImageUploadModal } from "../../../ui/molecules";
import { ImageLoad } from "../../download/template";

export const AvatarUpload = (props) => {
    const {
        file,
        setFileId,
        imageSize,
        style
    } = props;

    const [statusModal, setStatusModal] = useState(false);
    const [fileValue, setFileValue] = useState(null);

    const fileUpload = useCallback(() => {
        if (fileValue !== null) {
            let data = new FormData();
            data.append('file', fileValue);
            UploadFile(data)
                .then(response => {
                    if (response.status === 200) {
                        setFileId(response.data)
                    }
                })
                .catch(error => console.log(error.response))
        }
    }, [fileValue, setFileId])

    useEffect(() => {
        fileUpload()
    }, [fileUpload])

    return (
        <Fragment>
            <ImageUploadModal
                statusModal={statusModal}
                setStatusModal={setStatusModal}
                setFileValue={setFileValue}
            />
            <AvatarUploadLabel style={style} onClick={() => setStatusModal(true)}>
                {
                    file
                        ? <ImageLoad id={file} imageSize={imageSize} />
                        : <PersonSvg />
                }
                <CaptureSvg />
            </AvatarUploadLabel>
        </Fragment>
    )
}