import {useEffect, useState} from 'react';
import {useStore} from "effector-react";
import {$isDataPending} from "../../models/categories-attr-model";
import {attrCategoryMount} from "../../models/categories-attr-model/events";


export const JournalHooks = (dataInfo) => {

    const isDataPending = useStore($isDataPending);

    const [categoryData, setCategoryData] = useState(undefined);
    const [categoryName, setCategoryName] = useState('');
    const [attrName, setAttrName] = useState('');

    useEffect(() => {
        if (isDataPending.$dashboardCategoriesList.data && isDataPending.$dashboardCategoriesList.data.data && isDataPending.$dashboardCategoriesList.data.data.length > 0) {
            isDataPending.$dashboardCategoriesList.data.data.filter(item => item.id === Number(dataInfo.entry.categoryId) && setCategoryData(item))
        }

    }, [isDataPending.$dashboardCategoriesList.data]);

    useEffect(() => {
        if (categoryData && categoryData.id) {
            const dashboardAttr = isDataPending.$dashboardAttrList.data.filter(item => item.id === categoryData.id)[0];
            if (dashboardAttr && dashboardAttr.data && dashboardAttr.data.length > 0) {
                dashboardAttr.data.filter(item => item.id === Number(dataInfo.entry.attributeId) && setAttrName(item.name))
            }
        }

    }, [categoryData, isDataPending.$dashboardAttrList.data]);

    useEffect(() => {
        if (categoryData) {
            setCategoryName(categoryData.name);
        }
    }, [categoryData]);

    if (categoryData && isDataPending.$dashboardAttrList.data.filter(item => item.id === categoryData.id).length === 0) {
        attrCategoryMount(categoryData.id);
    }

    return {
        categoryName,
        attrName
    }
};