export const allOrgInfo = [
  {
    id: 1,
    title: 'count-employees',
    key: 'totalUsers'
  },
  {
    id: 2,
    title: 'total-length',
    key: 'totalLength'
  },
  {
    id: 3,
    title: 'count-directions',
    key: 'totalSectorCount',
    key2: 'totalSectorLength'
  },
  {
    id: 4,
    title: 'count-driving',
    key: 'totalSubSectorCount',
    key2: 'totalSubSectorLength'
  },
]

export const orgInfo = [
  {
    id: 1,
    title: "rrj",
    key: "name"
  },
  {
    id: 2,
    title: 'responsible-person',
    key: 'superiorName'
  },
  {
    id: 3,
    title: 'address',
    key: 'address'
  },
  {
    id: 4,
    title: 'phone-boss',
    key: 'superiorPhone'
  },
  {
    id: 5,
    title: 'phone-reception',
    key: 'receptionPhone'
  },
  {
    id: 6,
    title: 'count-employees',
    key: 'totalUsers'
  },
  {
    id: 7,
    title: 'total-length',
    key: 'totalLength',
  },
  {
    id: 8,
    title: 'count-directions',
    key: 'totalSectorCount',
    key2: 'totalSectorLength'
  },
  {
    id: 9,
    title: 'count-driving',
    key: 'totalSubSectorCount',
    key2: 'totalSubSectorLength'
  },
  {
    id: 10,
    title: 'count-station',
    key: 'totalStationCount',
  },
  {
    id: 11,
    title: 'count-station-driving',
    key: 'totalSecondaryStageCount',
    key2: 'totalSecondaryStageLength'
  }
]