import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  border: 1px solid #EDF1F7;
  border-radius: 8px;
  height: 100%;
`

export const DetailInfoHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .ant-picker {
    margin-left: auto
  }

  button {
    width: unset;
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 10px
  }

  @media (max-width: 1200px) {
    a.back-link {
      display: none;
    }
  }
`

export const ReportFormGroup = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`

export const ReportFormLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgb(137, 146, 163);
  margin-bottom: 8px; 
`

export const ReportInput = styled.button`
  padding: 0 16px;
  border: 1px solid rgb(228, 233, 242);
  height: 46px;
  background: rgb(250, 250, 251);
  cursor: pointer;
  color: rgb(34, 43, 69);
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  overflow: hidden;
  
  &[disabled] {
    opacity: .5;
    cursor: no-drop;
  }
`
export const ReportInputInner = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
`

export const ReportPlaceholder = styled.div`
  opacity: .4;
`

export const ReportValues = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  
  span {
    display: block;
    padding: 5px;
    
    &:first-child {
      padding-left: 0;  
    }
  }
`

export const ReportModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReportModalTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 16px;
  color: ${({theme}) => theme.main.semanticColor.basic[800]};
`

export const ReportListWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 360px;
  max-height: 360px;
  margin-bottom: 24px;
`

export const ReportListItem = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  border: 1px solid ${({theme}) => theme.main.semanticColor.basic[400]};
  border-top: 0;
  justify-content: space-between;
  
  &:first-child {
    border-top: 1px solid ${({theme}) => theme.main.semanticColor.basic[400]};
  }
`

ReportListItem.Title = styled.div`
  padding: 6px 12px;
  line-height: 24px;
  font-weight: 500;
  strong {
    font-weight: 700;
  }
`

export const ReportInfiniteScroll = styled(InfiniteScroll)`
  width: 100%;
`

export const ReportListTabs = styled.div`
  margin-bottom: 16px;
  
  .menu-wrapper {
    margin: 0 4px;  
  }
  
  svg {
    cursor: pointer;
  }
  
  .menu-item-wrapper {
    outline: none;
  }
`

export const ReportListTabItem = styled.div`
  padding: 4px 0;
  margin: 0 8px;
  line-height: 24px;
  font-weight: 600;
  font-size: 13px;
  height: 100%;
  white-space: nowrap;
  cursor: pointer;
  color: ${({theme}) => theme.main.semanticColor.basic[800]}
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 3px;
    width: 100%;
    height: 1px;
    background-color: ${({theme}) => theme.main.semanticColor.primary[500]};
    transform-origin: top left;
    transform: scaleX(${({active}) => active ? 1 : 0});
    transition: .25s ease;
  }
`

export const DetailTab = styled.div`
  font-weight: bold;
  cursor: pointer;
  color: ${({active}) =>  active ? '#222B45' : '#BCBCBC'};
  margin-bottom: 36px;
  line-height: 16px;
  text-transform: uppercase;
  padding-right: 20px;
  border-right: 1px solid #BCBCBC;
  padding-left: 20px;
  
  &:first-child {
    padding-left: 0;
  }
  
  &:last-child{
    padding-left: 20px;
    padding-right: 0;
    border: 0;
  }
`