import {URL_ID} from "../../constants/common";
import {USER_ROLES} from "../../constants/user";

export const getStringIds = (data) => {
    const arr = Object.values(data)
    if (arr.length === 0) {
        return false
    }
    const tmp = []
    for (let i = 0; i < arr.length; i++) {
        tmp.push(arr[i].id)
    }

    return tmp.join(',')
}

export const generateQueryParams = (allData) => {
    const selected = Object.keys(allData)
    const data = []
    for (let i = 0; i < selected.length; i++) {
        const ids = getStringIds(allData[selected[i]])
        const key = selected[i].toUpperCase()
        if (ids && URL_ID[key]) {
            const ref = URL_ID[key] === URL_ID.STATION_SECTOR ? URL_ID.SUB_SECTOR : URL_ID[key]
            data.push(`${ref}=${ids}`)
        }
    }
    return data.length > 0 && data.join('&')
}

export const matchByParent = (data, selected) => {
    let tmp = {}
    for (let a in selected) {
        for (let i in data) {
            const inner = data[i]
            for (let j in inner) {
                tmp[i] = tmp[i] ? {...tmp[i]} : {}
                if (inner[j].categoryId !== undefined && inner[j].categoryId === Number(a)) {
                    tmp[i][j] = inner[j]
                } else {
                    if (inner[j].parent !== undefined && inner[j].parent.id === Number(a)) {
                        tmp[i][j] = inner[j]
                    }
                }
            }
        }
    }
    return tmp
}

export const getStart = (data) => {
    return (data.startPoint * 1000) + data.countdownPoint
}

export const getEnd = (data) => {
    return (data.startPoint * 1000) + data.countdownPoint + data.length
}

export const objToArray = (obj) => {
    const tmp = []
    for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
            const arr = obj[i]

            for (let j = 0; j < arr.length; j++) {
                tmp.push(arr[j])
            }
        }
    }

    return tmp
}

export const getMaxValue = (arr) => {
    if (!Array.isArray(arr)) {
        return arr
    }
    return Math.max(...arr)
}

export const getMinValue = (arr) => {
    if (!Array.isArray(arr)) {
        return arr
    }
    return Math.min(...arr)
}

export const getInfo = (data, type = null) => {
    let fromValue = [], toValue = [], ids = []

    for (let i = 0; i < data.length; i++) {
        ids.push(data[i].id)

        if (type && type === 'sector') {
            fromValue.push(((data[i].startPoint * 1000) + data[i].countdownPoint))
            toValue.push(((data[i].startPoint * 1000) + data[i].countdownPoint + data[i].length))
        }

    }

    if (type === 'sector') {
        if (fromValue.length === 0 && toValue.length === 0 && ids.length === 0) {
            return false
        }
    } else {
        if (ids.length === 0) {
            return false
        }
    }

    return {
        from: fromValue.length > 0 ? getMinValue(fromValue) : fromValue,
        to: toValue.length > 0 ? getMaxValue(toValue) : toValue,
        ids
    }
}


export const isAdmin = (role) => {
    return role && (
        role === USER_ROLES.ADMIN
        || role === USER_ROLES.SUPER_ADMIN
        || role === USER_ROLES.BOSS
        || role === USER_ROLES.USER
    )
}

export const isEmployee = (role) => {
    return role && role === USER_ROLES.EMPLOYEE
}

export const getMatches = (ids, data) => {
    const tmp = []
    for (let i = 0; i < ids.length; i++) {
        for (let j = 0; j < data.length; j++) {
            if (data[j].categoryId !== undefined && data[j].categoryId === Number(ids[i])) {
                tmp.push(data[j])
            }

            if (data[j].parent && data[j].parent.id === Number(ids[i])) {
                tmp.push(data[j])
            }
        }
    }

    return tmp
}