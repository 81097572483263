import React, {useEffect, useState} from 'react';
import {Row, Col, Input} from 'antd';
import {ButtonGray, ModalCustom} from '../../../ui/atoms';
import {JournalSection, JournalLabels} from '../atoms';
import {
    HeaderRight,
    UserName
} from "../../../components/header/atoms";
import {CameraFillSvg, Edit2Svg} from '../../../media';
import Moment from "react-moment";
import {HeaderAvatar} from "../../../components/header/molecules";
import Dragger from "antd/es/upload/Dragger";
import InboxOutlined from "@ant-design/icons/lib/icons/InboxOutlined";
import {UploadFile} from "../../../components/avatar-upload/api";
import {GetImg} from "./get-img";
import {
    uploadCommentJournal,
    uploadPhotoJournal
} from "../api";
import {logList} from "../model";
import {$isDataPending} from '../../../models/categories-attr-model';
import {useStore} from "effector-react";
import {JournalHooks} from "../../../hooks/dashboard";
import {addLogList} from "../model/events";
import {useTranslation} from "react-i18next";

export const JournalBlock = (props) => {
    const {t} = useTranslation();
    const {
        dataInfo
    } = props;

    //status
    const [statusModal, setStatusModal] = useState(false);
    const [commentModalStatus, setCommentModalStatus] = useState(false);
    const [loading, setLoading] = useState(false);

    //ids
    const [fileData, setFileData] = useState(false);

    //data
    const [uploadId, setUploadId] = useState(undefined);
    const [message, setMessage] = useState(undefined);


    useEffect(() => {
        if (fileData){
            const data = new FormData();
            data.append('file', fileData);
            UploadFile(data)
                .then(response => {
                    if (response.status === 200) {
                        setUploadId(Number(response.data));
                    }
                })
                .catch(error => {
                    console.error(error.response)
                });
        }

    }, [fileData]);

    // useEffect(() => {
    //     getAttributeInf(dataInfo.refId)
    //         .then(response => {
    //             if (response.status === 200){
    //                 console.log(response.data)
    //             }
    //         })
    //         .catch(error => console.error(error.response))
    // }, []);

    const handleOk = () => {
        setLoading(true);
        const data = new Object(uploadId);
        uploadPhotoJournal(dataInfo.id, data)
            .then(response => {
                if (response.status === 200){
                    setLoading(false);
                    setStatusModal(false);
                    const params = {
                        ts: Date.now()
                    };
                    logList(params)
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error.response)
            })
    };

    const handleOkComment = () => {
        setLoading(true);
        const data = new Object(message);
        uploadCommentJournal(dataInfo.id, data)
            .then(response => {
                if (response.status === 200){
                    setLoading(false);
                    setCommentModalStatus(false);
                    const params = {
                        ts: Date.now()
                    };
                    addLogList(params)
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error.response)
            })
    };

    const {
        categoryName,
        attrName
    } = JournalHooks(dataInfo);

    return (
        <JournalSection>
            <ModalCustom
                visible={statusModal}
                headStyle={{display: 'none'}}
                okButtonProps={{
                    disabled: !uploadId,
                    loading: loading
                }}
                onOk={handleOk}
                onCancel={() => setStatusModal(false)}
            >
                <Dragger onChange={(e) => setFileData(e.fileList[0].originFileObj)}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    {
                        uploadId ?
                            <GetImg id={uploadId}/> :
                            <p className="ant-upload-text">{t('drag-file-message')}</p>
                    }
                </Dragger>
            </ModalCustom>
            <ModalCustom
                visible={commentModalStatus}
                headStyle={{display: 'none'}}
                okButtonProps={{
                    disabled: !message,
                    loading: loading
                }}
                onOk={handleOkComment}
                onCancel={() => setCommentModalStatus(false)}
            >
                <Input.TextArea
                    placeholder={t('change-comment')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </ModalCustom>

            <HeaderRight>
                {/* <BellSvg/> */}
                <div>
                    <UserName>
                        {`${dataInfo.user.name} ${dataInfo.user.sname}`}
                        <span>{dataInfo.user.position.title}</span>
                    </UserName>
                    <HeaderAvatar id={dataInfo.user.image}/>
                    {/*<img src={Avatar} alt='avatar'/>*/}
                </div>
                <Moment locale='ru' format="DD.MM.YYYY в hh:mm" date={dataInfo.createdDate}/>
            </HeaderRight>
            <JournalLabels>
                <span>{t('category')}:</span>
                <div>
                    {
                        categoryName
                    }
                </div>
            </JournalLabels>
            <JournalLabels>
                <span>{t('attribute')}:</span>
                <div>
                    {
                        attrName
                    }
                </div>
            </JournalLabels>
            <Row type='flex' gutter={16}>
                <Col span={6}>
                    <JournalLabels>
                        <span>{t('from')}:</span>
                        <div>
                            {
                                dataInfo.entry.from
                            }
                        </div>
                    </JournalLabels>
                </Col>
                <Col span={6}>
                    <JournalLabels>
                        <span>{t('to')}:</span>
                        <div>
                            {
                                dataInfo.entry.to
                            }
                        </div>
                    </JournalLabels>
                </Col>
                <Col span={12}>
                    <JournalLabels>
                        <span>{t('value')}:</span>
                        <div>
                            {
                                dataInfo.entry && dataInfo.entry.value ? dataInfo.entry.name ? dataInfo.entry.name : dataInfo.entry.type === 'DATE' ?
                                    <Moment locale='ru' format="DD/MM/YYYY"
                                            date={dataInfo.entry.value}/> : dataInfo.entry.value : ''
                            }
                        </div>
                    </JournalLabels>
                </Col>
                <Col span={24}>
                    <JournalLabels>
                        <span>{t('comment')}:</span>
                        {
                            dataInfo.comments && dataInfo.comments.map((comment, index) => <div key={index}>{comment.message}</div>)
                        }
                    </JournalLabels>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {
                        dataInfo.uploadId &&
                            <GetImg id={dataInfo.uploadId}/>
                    }
                </Col>
            </Row>
            <div style={{display: 'flex'}}>
                <ButtonGray onClick={() => setStatusModal(true)}><CameraFillSvg/> {t('add-photo')}</ButtonGray>
                <ButtonGray onClick={() => setCommentModalStatus(true)}><Edit2Svg/> {t('write-a-comment')}</ButtonGray>
            </div>

        </JournalSection>
    )
};