import React from 'react'
import {Checkbox} from "antd";
import {ReportListItem} from "../atoms";

export const ReportListsItem = ({item, selected, setSelected, multipleSelect}) => {

    const handleChange = (item) => {
        if (multipleSelect) {
            const data = {...selected}
            if (data[item.id]) {
                delete data[item.id]
            } else {
                data[item.id] = item
            }
            if (Object.keys(data).length === 0) {
                setSelected(null)
            } else {
                setSelected(data)
            }

        } else {
            if (selected && selected[item.id]) {
                setSelected(null)
            } else {
                setSelected({[item.id]: item})
            }
        }
    }

    return (
        <ReportListItem>
            <ReportListItem.Title>
                {item.name}
                {
                    item.length && <strong>{` (${item.length / 1000} km)`}</strong>
                }
            </ReportListItem.Title>
            <Checkbox
                checked={selected && selected[item.id]}
                onChange={() => handleChange(item)}
            />
        </ReportListItem>
    )
}