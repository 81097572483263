import React from "react";
import {NavLink, useHistory} from "react-router-dom";
import {Button, Tooltip} from 'antd';
import {CarouselButton, SectionCarousel} from "../atoms";
import {ArrowLeftSvg, ArrowRightSvg} from "../../../media";
import {useUrlParams} from "../../../hooks/common";
import {SectorsLengthHook} from "../../../hooks/dashboard";
import {nextEvent, previousEvent} from "../../../models/organization-model";

export const SecondCarousel = (props) => {
    const {
        sectorLength,
        km,
        tonnage,
        setSliderStatus,
    } = props;
    const history = useHistory();
    const {location} = history;
    const {sectorPoint, urlParams, subSectorId} = useUrlParams(location, ['sectorPoint']);
    const {organization} = SectorsLengthHook();

    return (
        <SectionCarousel
            slidesToScroll={10}
            slidesToShow={window.innerWidth > 1400 ? 15 : window.innerWidth >= 767 ? 8 : 12}
            framePadding={"5px 30px"}
            renderCenterLeftControls={({previousSlide}) => (
                <Button icon={<ArrowLeftSvg/>} onClick={() => {
                    previousEvent();
                    previousSlide();
                }}/>
            )}
            renderCenterRightControls={({nextSlide}) => (
                <Button icon={<ArrowRightSvg/>} onClick={() => {
                    nextSlide();
                    nextEvent();
                }}/>
            )}
        >
            {
                sectorLength ?
                    organization.$sectorLength.data && organization.$sectorLength.data.length > 0 ?
                        organization.$sectorLength.data.map(item => (
                            <span onClick={() => setSliderStatus(item.name)} key={item.name}>
                            {
                                km ? '0/0' : tonnage ? '0' : null
                            }
                        </span>
                        )) : null :
                    subSectorId && organization.$sectorLength.data && organization.$sectorLength.data.length > 0 ?
                        organization.$sectorLength.data.map((item, key) => (
                            <Tooltip key={key} placement="top" title={item.name + 1}>
                                <CarouselButton
                                    isactive={sectorPoint && sectorPoint.toString() === item.name.toString() ? 'true' : 'false'}
                                    type='link'
                                    key={item.name}
                                    onClick={() => history.push(`/?sectorPoint=${item.name}${urlParams ? '&' + urlParams : ''}`)}
                                >
                                    {item.name}
                                </CarouselButton>
                            </Tooltip>

                        )) :
                        <NavLink to='#'/>
            }
        </SectionCarousel>
    );
};