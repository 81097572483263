import {createStore} from "effector";
import {fetchGetAttrSector, fetchGoogleMapPoints} from "./effects";
import {filterPointsEvent, hoverSector, setDBPoints} from "./events";
import Cookies from 'js-cookie';
import {initDB, useIndexedDB} from "react-indexed-db";
import {DBConfig} from "../../DBConfig";

initDB(DBConfig);


const {update} = useIndexedDB('googlePoints');


export const $googlePoints = createStore({loading: false, data: [], filter: [], error: false})
    .on(fetchGoogleMapPoints.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchGoogleMapPoints.finally, (state, response) => {
        console.log('1')

        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            const data = [];
            const filter = [];
            const dataPoints = response.result.data;
            for (let i = 0; i < dataPoints.length; i++) {
                if (dataPoints[i][5] === null && !data.some(element => element.id === dataPoints[i][4])) {
                    const filteredFiles = dataPoints.filter((e) => {
                        return e[4] === dataPoints[i][4]
                    });
                    filter.push({
                        color: '#1890ff',
                        parentId: dataPoints[i][5],
                        id: dataPoints[i][4],
                        points: filteredFiles.map(item => ({
                            lat: item[2],
                            lng: item[3],
                            kilo: item[0],
                        }))
                    });
                    data.push({
                        color: '#1890ff',
                        parentId: dataPoints[i][5],
                        id: dataPoints[i][4],
                        points: filteredFiles.map(item => ({
                            lat: item[2],
                            lng: item[3],
                            kilo: item[0],
                        }))
                    });
                } else if (!data.some(element => element.id === dataPoints[i][4])) {
                    const filteredFiles = dataPoints.filter((e) => {
                        return e[4] === dataPoints[i][4]
                    });

                    data.push({
                        color: '#1890ff',
                        parentId: dataPoints[i][5],
                        id: dataPoints[i][4],
                        points: filteredFiles.map(item => ({
                            lat: item[2],
                            lng: item[3],
                            kilo: item[0],
                        }))
                    })
                }
            }

            update({
                id: 1, pointArray: JSON.stringify({
                    ...state,
                    error: false,
                    data: data,
                    defaultFilter: filter,
                    filter: filter
                })
            }).then(
                event => {
                    Cookies.set('tokenTime', Date.parse(new Date()))
                    // console.log('ID Generated: ', event);
                },
                error => {
                    // console.log(error);
                }
            );
            return {
                ...state,
                error: false,
                data: data,
                defaultFilter: filter,
                filter: filter
            }
        }
    })
    .on(hoverSector, (state, response) => {
        // console.log(state, response)
    })
    .on(setDBPoints, (state, response) => {
        console.log('2')

        return {
            ...state,
            ...response,
            loading: false
        }
    })
    .on(filterPointsEvent, (state, response) => {
        console.log('3')
        return {
            ...state,
            filter: response
        }
    });


export const $filteredPoints = createStore({loading: false, data: [], error: false})
    .on(filterPointsEvent, (state, response) => {
        return {
            ...state,
            data: response
        }
    })
    .on(fetchGetAttrSector.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchGetAttrSector.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            const dataKilo = [];
            if (response.result.data && response.result.data.length > 0) {
                for (let i = 0; i < response.result.data.length; i++) {
                    if (Number(response.result.data[i].toMeter) - Number(response.result.data[i].fromMeter) > 1000) {
                        for (let q = Math.floor10(response.result.data[i].fromMeter / 1000); q < Math.floor10(response.result.data[i].toMeter / 1000); q++) {
                            dataKilo[q] = {
                                color: response.result.data[i].entry.color
                            };
                        }

                    } else {
                        dataKilo[Number(Math.floor10(response.result.data[i].fromMeter / 1000))] = {
                            color: response.result.data[i].entry.color
                        };
                    }
                }
            }

            const filterKiloFunction = (data) => {
                const filteredKilo = [];
                for (let i = data.points[0].kilo; i < (data.points[data.points.length - 1].kilo + 1); i++) {
                    filteredKilo.push({
                        kilo: i,
                        kiloPoints: i < (data.points[data.points.length - 1].kilo + 1) ? [...data.points.filter(item => item.kilo === i), data.points.filter(item => item.kilo === i + 1)[0]]
                            : data.points.filter(item => item.kilo === i),
                        ...dataKilo[i]
                    })
                }
                return filteredKilo
            };
            return {
                ...state,
                data: state.data.map(item => item.id === response.params.sectorId ?
                    {
                        ...item,
                        kilo: filterKiloFunction(item)
                    }
                    : {...item})
            }
        }
    });