import {useEffect} from "react";
import {allOrgInfoMount} from "../../models/report-model";
import {useHistory} from 'react-router-dom'
import {useUrlParams} from "../common";

export const useAllOrganizationInfo = () => {
  const {location} = useHistory()
  const {organizationId} = useUrlParams(location)

  useEffect(() => {
    let timeout = null
    timeout = setTimeout(() => {
      if(!organizationId){
        allOrgInfoMount()
      }
    }, 100)

    return () => {
      clearTimeout(timeout)
      timeout = null
    }
  }, [organizationId])
}