import styled from 'styled-components';
import { Button as ButtonAntd } from "antd";
import { getColorType } from "../../helpers";
// import { getColor } from '../../helpers';

export const Button = styled(ButtonAntd)`
  &.ant-btn{
      height: 40px;
      display: flex;
      background: ${props => props.type ? props.type === 'link' ? 'transparent !important' : getColorType(props.type)[500] : getColorType('primary')[500]};
      text-align: center;
      color: ${props => props.type === 'link' ? props.active ? '#222B45 !important' : '#3366FF' : '#fff'};
      border-radius: 8px;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      padding: ${props => props.noChildren ? '0 16px' : '0 30px'};
      transition: .3s;
      outline: none;
      border: ${props => props.type === 'link' ? 'none' : 'none'};
      align-items: center;
      justify-content: center;
      flex-direction: ${props => props.noChildren ? 'column' : 'row'};
      width: ${props => props.noChildren ? '40px' : 'initial'};
      span{
        background: transparent;
        color: inherit;
        border: none;
        text-transform: uppercase;
      }
      svg{
        //width: 20px;
        color: ${props => props.active ? '#3366FF' : 'inherit'};
        margin-right: ${props => props.noChildren ? 0 : 8}px !important;
        /* margin-left: ${props => props.noChildren ? 0 : -8}px !important; */
      }
      &:hover{
        color: ${props => props.type === 'link' ? '#598BFF' : '#fff'};
        background: ${props => props.type ? props.type === 'link' ? '#F7F9FC' : getColorType(props.type)[400] : getColorType('primary')[400]};
      }
      &:active{
        color: ${props => props.type === 'link' ? '#274BDB' : '#fff'};
        background: ${props => props.type ? props.type === 'link' ? '#EDF1F7' : getColorType(props.type)[600] : getColorType('primary')[600]};
      }
      &:focus{
        color: ${props => props.type === 'link' ? '#274BDB' : '#fff'};
        box-shadow: ${props => props.type === 'link' ? 'none' : '0 0 0 4px rgba(143, 155, 179, 0.16)'};
        background: ${props => props.type ? props.type === 'link' ? 'transparent' : getColorType(props.type)[600] : getColorType('primary')[600]} !important;
      }
      &:disabled{
        background: rgba(143, 155, 179, 0.16);
        border-color: rgba(143, 155, 179, 0.24);
        color: #8F9BB3;
        opacity: 0.48;
      }

      @media (max-width: 1440px) {
        font-size: 13px;
        height: 32px;
      }
  }
`;

export const ButtonGray = styled(Button)`
  margin-right: 10px;
  &.ant-btn{
      display: flex;
      background: #EDF1F7;
      text-align: center;
      color: #2E3A59;
      border-radius: 8px;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      padding: 6px 9px;
      transition: .3s;
      outline: none;
      border: 8px;
      align-items: center;
      justify-content: center;
      font-style: normal;
      text-transform: uppercase;
      span{
        background: transparent;
        color: inherit;
        border: none;
      }
      svg{
        width: 20px;
        color: ${props => props.active ? '#3366FF' : 'inherit'};
        margin-right: ${props => props.noChildren ? 0 : 8}px !important;
        margin-left: 0 !important;
        /* margin-left: ${props => props.noChildren ? 0 : -8}px !important; */
      }
      /* &:hover{
        color: ${props => props.type === 'link' ? '#598BFF' : '#fff'};
        background: ${props => props.type === 'link' ? '#F7F9FC' : '#598BFF'};
      }
      &:active{
        color: ${props => props.type === 'link' ? '#274BDB' : '#fff'};
        background: ${props => props.type === 'link' ? '#EDF1F7' : '#274BDB'};
      }
      &:focus{
        color: ${props => props.type === 'link' ? '#274BDB' : '#fff'};
        box-shadow: ${props => props.type === 'link' ? 'none' : '0 0 0 4px rgba(143, 155, 179, 0.16)'};
        background: ${props => props.type === 'link' ? 'transparent' : '#274BDB'} !important;
      }
      &:disabled{
        background: rgba(143, 155, 179, 0.16);
        border-color: rgba(143, 155, 179, 0.24);
        color: #8F9BB3;
        opacity: 0.48;
      } */
  }
`;