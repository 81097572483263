import React, {Fragment, useEffect, useState} from 'react'
import {ModalSectors} from "../../../ui/molecules";
import {Col, Row} from "antd";
import {SectorsBlock, SectorsBlockTitle} from "../atoms";
import {ArrowBottomSvg} from "../../../media";
import {GoogleMapComponent, InfoTable, RightSidebar, SecondCarousel, UserActions} from "../molecules";
import {useLocation} from 'react-router-dom'
import {UnderMainCarousel} from "../molecules";
import {RedirectHooks, SectorsHooks} from "../../../hooks/dashboard";
import {useTranslation} from "react-i18next";

export const MainBlock = (props) => {
    const location = useLocation();
    const {t} = useTranslation();
    //status
    const [sliderStatus, setSliderStatus] = useState(false);
    const [sectorBlockStatus, setSectorBlockStatus] = useState(false);

    //info
    const [tabType, setTabType] = useState(undefined);

    useEffect(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('tabType')) {
            setTabType(searchParams.get('tabType'));
        } else {
            setTabType(undefined);
        }
        searchParams.delete('tabType');
    }, [location.search]);


    SectorsHooks(props);
    RedirectHooks();

    return (
        <Fragment>
            <UnderMainCarousel/>
            <Row type="flex" style={{marginTop: 15, minHeight: 'calc(100vh - 224px)'}} gutter={24}>
                <ModalSectors
                    statusModal={sliderStatus}
                    setStatusModal={setSliderStatus}
                />
                <Col span={24} xl={18} xxl={19}>
                    {
                        tabType === undefined || tabType === 'map' ?
                            <Row gutter={24}>
                                <Col span={24}>
                                    <GoogleMapComponent/>
                                </Col>
                            </Row> :
                            tabType === 'list' ?
                                <Row type='flex' gutter={24}>
                                    <Col span={24}>
                                        <SectorsBlock style={{zIndex: 3}} visible={true}>
                                            <SectorsBlockTitle visible={sectorBlockStatus} onClick={() => setSectorBlockStatus(!sectorBlockStatus)}>
                                                {t('km')}
                                                <ArrowBottomSvg/>
                                            </SectorsBlockTitle>
                                            <SecondCarousel sectorType/>
                                        </SectorsBlock>
                                        <SectorsBlock style={{zIndex: 2}} visible={sectorBlockStatus} status={sliderStatus}>
                                            <SectorsBlockTitle>
                                                {t('km-h')}
                                            </SectorsBlockTitle>
                                            <SecondCarousel setSliderStatus={setSliderStatus} km sectorLength/>
                                        </SectorsBlock>
                                        <SectorsBlock style={{zIndex: 1}} visible={sectorBlockStatus} status={sliderStatus}>
                                            <SectorsBlockTitle>
                                                {t('pt')}
                                            </SectorsBlockTitle>
                                            <SecondCarousel setSliderStatus={setSliderStatus} tonnage sectorLength/>
                                        </SectorsBlock>
                                    </Col>
                                    <Col span={14}>
                                        <InfoTable/>
                                    </Col>
                                    <Col span={10}>
                                        <UserActions/>
                                    </Col>
                                </Row> : null
                    }
                </Col>
                <Col span={24} xl={6} xxl={5} style={{paddingBottom: 15}}>
                    <RightSidebar/>
                </Col>
            </Row>
        </Fragment>
    )
};