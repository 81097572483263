import styled from "styled-components";

export const SummaryItem = styled.div`
  padding: 12px 24px;
  height: 100%;
  font-weight: ${({title}) => title && title ? 'normal' : '600'};
  border-right: 1px solid ${({theme}) => theme.main.semanticColor.basic[300]};
  position: ${({title}) => title && title ? 'sticky' : 'static'};
  left: ${({title}) => title && title ? '0' : 'unset'};
  font-size: 14px;
  color: ${({title}) => title && title ? '#8F9BB3' : '#222B45'};
  background-color: #fff;

  &:last-child {
    border-right: 0;
  }

  span {
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    text-decoration: underline;
    color: ${({theme}) => theme.main.semanticColor.primary[500]};
  }
`

export const InfoGrid = styled.div`
  grid-template-columns: 400px 1fr;
  display: grid;

  ${SummaryItem} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &:nth-child(even) ${SummaryItem} {
    background-color: #F6F7F9;
  }
`

export const ValueItem = styled.div`
  padding: 0 9px;
  white-space: nowrap;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`

export const InfoInner = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReportCatTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  padding: 18px 24px;

  div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  svg {
    transform: scaleY(${({status}) => status ? 1 : -1});
    margin-top: 4px;
    transition: .3s ease;
  }
`

export const DetailInfoContainer = styled.div`
  overflow: auto;
  margin-bottom: 24px;
  position: relative;
  max-height: calc(100vh - 100px);

  scrollbar-color: rgba(0, 0, 0, .3) #fff;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .3);
    border-radius: 4px;
  }

  ${InfoGrid} {
    position: relative;
  }

  ${ReportCatTitle} {
    position: sticky;
    left: 0;
    width: 100%;
  }

  ${SummaryItem} {
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const ReportTable = styled.div`
  display: table;
`

export const InfoHeader = styled.div`
  position: sticky;
  z-index: 12;
  top:0

  ${InfoGrid} {
    background-color: #F6F7F9;
  }
  
  ${SummaryItem} {
    border: 0;
    background-color: #F6F7F9
  }

  ${ReportCatTitle}{
    color: ${({ theme }) => theme.main.semanticColor.primary[500]};
    background-color: #F6F7F9;
  }
`

export const InfoFlex = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  
  ${SummaryItem} {
    max-width: 200px;
  }
`