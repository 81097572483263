import {useHistory} from 'react-router-dom'
import {useUrlParams} from "../common";
import {useCallback, useEffect, useState} from "react";
import {organizationFullInfoMount, organizationInfoMount,} from "../../models/report-model";
import {useStore} from "effector-react";
import {$userModel} from "../../models/user-model";
import {isAdmin} from "./helper";
import {orgUserListMount} from "../../models/info-model";

export const useOrganizationInfo = () => {
    const [statusModal, setStatusModal] = useState(false)
    const [search, setSearch] = useState('')
    const {location} = useHistory()
    const {organizationId} = useUrlParams(location)
    const {$userRole} = useStore($userModel)

    const getOrgInfo = useCallback(() => {
        if (organizationId) {
            organizationInfoMount(organizationId)
        }
    }, [organizationId])

    const getOrgPermission = useCallback(() => {
        if (isAdmin($userRole) && organizationId) {
            orgUserListMount(organizationId)
        }
    }, [$userRole, organizationId])

    const getOrgFullInfo = useCallback(() => {
        if (organizationId) {
            console.log(organizationId);
            organizationFullInfoMount(organizationId)
        }
    }, [organizationId])


    useEffect(() => {
        getOrgInfo()
    }, [getOrgInfo])

    useEffect(() => {
        getOrgFullInfo()
    }, [getOrgFullInfo])

    return {
        getOrgPermission,
        statusModal,
        setStatusModal,
        search,
        setSearch
    }
}