import {useCallback, useState} from "react"
import {summaryMount, summaryDetailMount, allSummaryMount} from '../../models/report-model'
import {convertTo} from "../../helpers";
import {getStationAllSummary} from "../../api/report-api";

const {Math} = convertTo();

export const useSummary = (props) => {
    const {
        attributeIds,
        startValue,
        endValue,
        subSectorIds,
        sectorIds,
        dateValue,
        attributeList,
        subSectorList,
        categoryIds,
        showRange,
        showDetail,
        sectorStationList,
        sectorStationIds,
        showStation,
        stationIds
    } = props

    const [slideUpStatus, setSlideUpStatus] = useState({})
    const [check, setCheck] = useState(false)
    const [thead, setThead] = useState([])
    const [tbody, setTbody] = useState([])
    const [fake, setFakse] = useState([])

    const getOnlyId = (arr) => {
        let tmp = []

        for (let i = 0; i < arr.length; i++) {
            tmp.push(arr[i].value)
        }

        return tmp
    }

    const getMaxValue = (arr) => {
        if (!Array.isArray(arr)) {
            return arr
        }
        return Math.max(...arr)
    }

    const getMinValue = (arr) => {
        if (!Array.isArray(arr)) {
            return arr
        }
        return Math.min(...arr)
    }

    const getMatches = (a, b) => {
        let tmp = []
        for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < b.length; j++) {
                if (a[i] === b[j].value) {
                    tmp.push(b[j])
                }
            }
        }
        return tmp
    }

    const generateSliderState = useCallback(() => {
        const tmp = {}
        const data = categoryIds
        if (data) {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    tmp[data[i]] = true
                }
                setSlideUpStatus(tmp)
            }
        } else {
            setSlideUpStatus({})
        }
    }, [categoryIds])


    const generateThead = useCallback(() => {
        const start = Math.floor10(startValue / 1000)
        const end = Math.floor10(endValue / 1000)
        let tmp = []

        for (let i = start; i < end + 1; i++) {
            tmp.push(i)
        }
        setFakse(tmp)
        setThead([{title: 'Километры', data: tmp}])

    }, [startValue, endValue])

    const generateTbody = useCallback(() => {
        const start = Math.floor10(startValue / 1000)
        const end = Math.floor10(endValue / 1000)
        const attrs = attributeList.length > 0 && attributeIds && attributeIds.length > 0 ? getMatches(attributeIds, attributeList) : attributeList
        if (attrs.length > 0) {
            const tmp = []
            let parentIds = {}
            let attrIds = {}
            const mainData = []
            const test = {}
            for (let i = 0; i < attrs.length; i++) {
                parentIds[attrs[i].parentId] = attrs[i].parentId
                attrIds[attrs[i].value] = {id: attrs[i].value, name: attrs[i].label, parentId: attrs[i].parentId}
                for (let j = start; j < end + 1; j++) {
                    tmp.push({parentId: attrs[i].parentId, id: attrs[i].value, start: j, end: j + 1})
                    test[attrs[i].parentId] = {
                        parentId: attrs[i].parentId,
                        data: attrs.map(item => {
                            if (item.parentId === attrs[i].parentId) {
                                return (
                                    {
                                        name: item.label,
                                        data: attrs.filter(x => x.id === item.id)
                                    }
                                )
                            }
                        }).filter(item => item !== undefined)
                    }
                }
            }

            parentIds = Object.values(parentIds)
            attrIds = Object.values(attrIds)
            for (let i = 0; i < parentIds.length; i++) {

                mainData.push({
                    parentId: parentIds[i],
                    data: attrIds.map(x => {
                        if (x.parentId === parentIds[i]) {
                            return (
                                {
                                    name: x.name,
                                    data: tmp.filter(item => item.parentId === x.parentId && item.id === x.id)
                                }
                            )
                        }
                    }).filter(item => item !== undefined)
                })
            }
            setTbody(mainData)
        }

    }, [attributeList, attributeIds, startValue, endValue])


    const getCommonSummary = useCallback(() => {

        const data = {
            attributeIds: [],
            fromMeter: getMinValue(startValue),
            sectorIds: [],
            toMeter: getMaxValue(endValue),
            ts: dateValue
        }

        const summaryData = {
            attributeList: attributeList,
        }


        if (categoryIds === undefined /* && categoryIds.length === 0 */) {
            return false
        }

        if (sectorIds === undefined /* && sectorIds.length === 0 */) {
            return false
        }

        // if (Number(endValue) === 0 || Number(startValue) === 0) {
        //     return false
        // }


        if (subSectorIds !== undefined && (Array.isArray(subSectorIds) && subSectorIds.length > 0)) {
            data.sectorIds = subSectorIds
        } else {
            data.sectorIds = getOnlyId(subSectorList)
        }

        if (attributeIds !== undefined || (Array.isArray(attributeIds) && attributeIds.length > 0)) {
            data.attributeIds = attributeIds
            summaryData['attributeList'] = getMatches(attributeIds, attributeList)
        } else {
            if (attributeList.length > 0) {
                data.attributeIds = getOnlyId(attributeList)
            }
        }

        summaryData['data'] = data
        summaryMount(summaryData)

    }, [attributeIds, sectorIds, startValue, endValue, subSectorIds, dateValue, categoryIds, attributeList, subSectorList])

    const changeSlideUpStatus = useCallback((id) => {
        let timeout = null
        const data = slideUpStatus

        if (timeout) {
            clearTimeout(timeout)
            timeout = null
        }
        setCheck(true)

        data[id] = !data[id]

        setSlideUpStatus(data)

        timeout = setTimeout(() => {
            setCheck(false)
            clearTimeout(timeout)
            timeout = null
        })

    }, [slideUpStatus])

    const getDetailSummary = useCallback(() => {
        generateThead()
        generateTbody()

        if (subSectorIds && subSectorIds.length > 0 && subSectorList.length > 0 && attributeList.length > 0) {
            const attrs = attributeIds ? getMatches(attributeIds, attributeList) : attributeList
            const sectorId = getMatches(subSectorIds, subSectorList)[0].value

            for (let i = 0; i < attrs.length; i++) {
                const data = {
                    attributeId: attrs[i].value,
                    sectorId: sectorId,
                    from: startValue,
                    to: endValue,
                    params: {ts: dateValue}
                }

                summaryDetailMount(data)
            }
        }
    }, [subSectorList, attributeList, attributeIds, subSectorIds, startValue, endValue, dateValue, generateThead, generateTbody])

    const getStationSummary = useCallback(() => {
        const data = {
            attributeIds: [],
            fromMeter: getMinValue(startValue),
            sectorIds: [],
            toMeter: getMaxValue(endValue),
            ts: dateValue
        }


        if (sectorStationIds !== undefined && (Array.isArray([sectorStationIds]) && [sectorStationIds].length > 0)) {
            data.sectorIds = [sectorStationIds]
        } else {
            data.sectorIds = getOnlyId(sectorStationList)
        }

        if (attributeIds !== undefined || (Array.isArray(attributeIds) && attributeIds.length > 0)) {
            data.attributeIds = attributeIds
        } else {
            if (attributeList.length > 0) {
                data.attributeIds = getOnlyId(attributeList)
            }
        }


        console.log(data);
        getStationAllSummary({id: stationIds, data})
            .then(res => {
                console.log(res);
            })
    }, [stationIds, sectorStationIds, dateValue, sectorStationList, startValue, endValue])

    const getSummary = useCallback(() => {
        generateSliderState()
        if (showStation) {
            getStationSummary()
        } else {
            if (showDetail && showRange) {
                getDetailSummary()
            } else {
                getCommonSummary()
            }
        }

    }, [showDetail, showRange, getDetailSummary, getCommonSummary, generateSliderState, getStationSummary])

    const getAllSummary = useCallback(() => {
        const data = {
            attributeIds: [],
            fromMeter: 0,
            toMeter: 100000000,
            ts: dateValue
        }
        generateSliderState()

        const summaryData = {
            attributeList: attributeList,
        }
        if (categoryIds === undefined /* && categoryIds.length === 0 */) {
            return false
        }

        if (attributeIds !== undefined || (Array.isArray(attributeIds) && attributeIds.length > 0)) {
            data.attributeIds = attributeIds
            summaryData['attributeList'] = getMatches(attributeIds, attributeList)
        } else {
            if (attributeList.length > 0) {
                data.attributeIds = getOnlyId(attributeList)
            }
        }

        summaryData['data'] = data
        allSummaryMount(summaryData)
    }, [dateValue, categoryIds, attributeList, attributeIds, generateSliderState])

    return {getSummary, slideUpStatus, changeSlideUpStatus, thead, tbody, fake, getAllSummary}
}