import {combine, createStore} from "effector";
import {
    fetchStationList
} from "./effects";
import {stationSubsMount} from "./events";

export const $stationList = createStore({loading: false, data: [], result: [], error: false})
    .on(fetchStationList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchStationList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data,
                error: false
            };
        }
    })
    .on(stationSubsMount, (state, response) => {
        if (response.length > 0) {
            return {
                ...state,
                data: state.data.map(item => ({
                    ...item,
                    subSectors: response.filter(responseItem => responseItem.station !== null && responseItem.station.id === item.id)
                }))
            }
        }
    })


export const $isDataPending = combine({
    $stationList
})