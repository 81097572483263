import React, {useCallback, useEffect, useState} from "react";
import {useUrlParams} from "../common";
import {AttributeInfo} from "../../features/dashboard/molecules";
import {InfoCircleOutlined} from "@ant-design/icons";
import {useLocation} from "react-router-dom";
import {useStore} from "effector-react";
import {$isDataPending} from "../../models/categories-attr-model";
import {attrCategoryMount} from "../../models/categories-attr-model/events";
import {getCategoryAttrListEvent} from "../../models/info-table-model/event";
import {openNotificationWithIcon} from "../../helpers";


export const InfoTableHooks = () => {
    const location = useLocation();
    const isDataPending = useStore($isDataPending);

    const {categoryId, subSectorId, sectorPoint} = useUrlParams(location);

    const [statusModal, setStatusModal] = useState(false);
    const [attributeInfo, setAttributeInfo] = useState([]);
    const [colorArray, setColorArray] = useState([])

    const setAttrInfo = useCallback((categoryId) => {
        if (categoryId && isDataPending.$dashboardAttrList.data.filter(item => item.id === Number(categoryId)).length === 0) {
            attrCategoryMount(Number(categoryId));
        }
    }, [isDataPending.$dashboardAttrList.data]);

    useEffect(() => {
        setAttrInfo(categoryId);
    }, [categoryId]);

    useEffect(() => {
        isDataPending.$dashboardAttrList.data.filter(item => item.id === Number(categoryId) && getCategoryAttrListEvent(item))
    }, [categoryId, isDataPending.$dashboardAttrList.data]);


    const openModal = (record) => {
        if (subSectorId && sectorPoint) {
            setAttributeInfo(record);
            setStatusModal(true);
        } else {
            openNotificationWithIcon('warning', 'Предупреждение!', 'Выберите участок и его километр!');
        }

    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Value",
            dataIndex: "value",
            key: "value",
            width: '200px',
            render: value => (
                <AttributeInfo statusModal={statusModal} colorArray={colorArray} setColorArray={setColorArray} id={value}/>
            )
        },
        {
            title: "Action",
            key: "action",
            render: record => (
                <div
                    onClick={() => openModal(record)}
                    style={{textAlign: "center", color: colorArray[record.id] && colorArray[record.id].color ? colorArray[record.id].color : 'initial'}}
                >
                    <InfoCircleOutlined/>
                </div>
            )
        }
    ];

    return {
        categoryId,
        columns,
        setStatusModal,
        attributeInfo,
        statusModal
    }
};