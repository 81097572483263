import {createEffect} from "effector";
import {
    getInfoAllOrganization,
    getSummary,
    getAttributeSummary,
    getAllSummary,
    getFullInfo
} from "../../api/report-api";
import {
    getOrganizationInfo,
    getOrganizationSectorList
} from "../../api/organization-api";
import {
    getStationList,
    getSubSectorList
} from "../../api/sector-api";
import {
    getAttributeCategory,
    getCategoriesList
} from "../../api/category-api";
import {getStationSector} from "../../api/station-api";

export const fetchAllOrgInfo = createEffect({
    handler: getInfoAllOrganization
})

export const fetchSummary = createEffect({
    handler: getSummary
})

export const fetchDetailSummary = createEffect({
    handler: getAttributeSummary
})

export const fetchAllSummary = createEffect({
    handler: getAllSummary
})

export const fetchOrganizationInfo = createEffect({
    handler: getOrganizationInfo
})

export const fetchOrganizationSector = createEffect({
    handler: getOrganizationSectorList
})

export const fetchSubSector = createEffect({
    handler: getSubSectorList
})

export const fetchStationSubSector = createEffect({
    handler: getStationSector
})

export const fetchStation = createEffect({
    handler: getStationList
})

export const fetchCategory = createEffect({
    handler: getCategoriesList
})

export const fetchAttribute = createEffect({
    handler: getAttributeCategory
})

export const fetchOrganizationFullInfo = createEffect({
    handler: getFullInfo
})