import React, {Fragment} from 'react'
import {UserName, UserProfileWrapper} from "../atoms";
import {HeaderAvatar} from "../molecules";
import {ProfileMenu} from "../molecules";
import {useSignedUserInfo} from "../../../hooks/common";
import { useStore } from 'effector-react';
import { $userModel } from '../../../models/user-model';
import { USER_ROLES } from '../../../constants/user';

export const UserProfile = () => {
    const {$signedUserInfo} = useSignedUserInfo()
    const {$userRole} = useStore($userModel)
    return (
        <Fragment>
            {!$signedUserInfo.loading
                ? <Fragment>
                    {Object.keys($signedUserInfo.data).length && $userRole !== USER_ROLES.ADMIN && $userRole !== USER_ROLES.SUPER_ADMIN 
                        ? <ProfileMenu logout={false}>
                            <UserProfileWrapper>
                                <UserName>
                                    {$signedUserInfo.data.username}
                                    <span>{$signedUserInfo.data.position && $signedUserInfo.data.position.title && $signedUserInfo.data.position.title}</span>
                                </UserName>
                                {
                                    $signedUserInfo.data.image && <HeaderAvatar id={$signedUserInfo.data.image}/>
                                }
                            </UserProfileWrapper>
                        </ProfileMenu>
                        : <ProfileMenu logout={true}>
                            <UserProfileWrapper>
                                <UserName>
                                    Admin
                                </UserName>
                                <HeaderAvatar/>
                            </UserProfileWrapper>
                        </ProfileMenu>}
                </Fragment>
                : null
            }
        </Fragment>
    )
}
