import React from 'react'
import {Col, Row} from "antd";
import {ReportForm, ReportInfo, ReportSummary} from "../organisms";

export const ReportPage = () => {
  return (
    <Row gutter={24}>
      <Col span={8}>
        <ReportInfo />
      </Col>
      <Col span={16}>
        <ReportForm />
      </Col>
        <Col span={24} style={{marginTop: 24}}>
            <ReportSummary />
        </Col>
    </Row>
  )
}