import React from 'react';
import {useStore} from "effector-react";
import {GoogleApiWrapper, InfoWindow, Map, Polyline} from "google-maps-react";
import {MapAttrGroup, MapAttrGroupItems, MapAttrGroupTitle, MapContainer, MapLoader, SectorInfoWindow} from "../atoms";
import {GetPointsHooks} from "../../../hooks/dashboard";
import {$googlePoints} from "../../../models/google-map";
import {$isDataPending} from "../../../models/info-table-model";
import {NavLink} from "react-router-dom";
import {$filteredPoints} from "../../../models/google-map/store";
import {useTranslation} from "react-i18next";
import {ExpandSvg, CollapseSvg} from "../../../media";


const GoogleMapComponentView = (props) => {
    const {t} = useTranslation();
    const googlePoints = useStore($googlePoints);
    const filteredPoints = useStore($filteredPoints);
    const isDataPending = useStore($isDataPending);

    const {
        onPolylineClick,
        centerWindow,
        showingInfoWindow,
        sectorInfoData,
        mapLoaded,
        sectorId,
        parentSymbol,
        symbolThree,
        urlParams,
        zoomChange,
        zoom,
        attributeId,
        attrStatus,
        setAttrStatus,
        setShowingInfoWindow,
        categoryName
    } = GetPointsHooks(props);


    const checkPoint = (data) => {
        const filterData = []
        if (data.length > 0){
            for (let i = 0; i < data.length; i++){
                if (data[i] !== undefined){
                    filterData.push(data[i])
                }
            }
            return filterData
        }
    }

    console.log('googlePoints', googlePoints)

    return (
        <MapContainer>
            <MapLoader status={googlePoints.loading}>
                {t('loading-map')}...
            </MapLoader>
            <Map
                google={props.google}
                // style={{height: '600px', width: '100%', padding: '24px'}}
                center={centerWindow}
                initialCenter={centerWindow}
                zoom={zoom}
                // fullscreenControl={false}
                onReady={(mapProps, map) => mapLoaded(mapProps, map)}
                onZoom_changed={(mapProps, map) => zoomChange(mapProps, map)}
            >
                {
                    zoom > 11 && sectorId ?
                        filteredPoints.data && filteredPoints.data.length > 0 &&
                        filteredPoints.data.map((mapPolyline) =>
                            mapPolyline.kilo && mapPolyline.kilo.length > 0 && mapPolyline.kilo.map((item, i) =>
                                <Polyline
                                    text={item.kilo}
                                    key={i}
                                    icons={[
                                        {
                                            icon: symbolThree,
                                            offset: '0%'
                                        },
                                        {
                                            icon: parentSymbol,
                                            offset: '100%'
                                        }
                                    ]}
                                    path={item && item.kiloPoints && checkPoint(item.kiloPoints)}
                                    strokeColor={item.color ? item.color : 'rgba(24, 144, 255, 0.50)'}
                                    strokeOpacity={1}
                                    strokeWeight={8}
                                    onClick={(props, e) => onPolylineClick(props, e, mapPolyline)}

                                />
                            ))
                        : googlePoints.filter && googlePoints.filter.length > 0 &&
                        googlePoints.filter.map((mapPolyline, index) =>
                            <Polyline
                                text='dsfdsf'
                                key={index}
                                icons={[
                                    {
                                        icon: mapPolyline.parentId === null ? parentSymbol : symbolThree,
                                        offset: '0%'
                                    },
                                    {
                                        icon: mapPolyline.parentId === null ? parentSymbol : symbolThree,
                                        offset: '100%'
                                    }
                                ]}
                                path={mapPolyline.points}
                                strokeColor='#1890ff'
                                strokeOpacity={1}
                                strokeWeight={zoom === 6 ? 4 : 2}
                                onClick={(props, e) => onPolylineClick(props, e, mapPolyline)}

                            />
                        )
                }
                <InfoWindow
                    onClose={() => setShowingInfoWindow(false)}
                    visible={showingInfoWindow}
                    position={centerWindow}
                >
                    <SectorInfoWindow>
                        <div>
                            <span>{t('name1')}:</span>
                            <span>{sectorInfoData.name}</span>
                        </div>
                        {/*<div>*/}
                        {/*    <span>{t('count-directions')}:</span>*/}
                        {/*    <span>{sectorInfoData.parent && sectorInfoData.parent.length}</span>*/}
                        {/*</div>*/}
                        {
                            !sectorId &&
                            <div>
                                <span>{t('count-driving')}: </span>
                                <span>{sectorInfoData.subsLength}</span>
                            </div>
                        }
                        <div>
                            <span>{t('start')}: </span>
                            <span>{sectorInfoData.startPoint}</span>
                        </div>
                        <div>
                            <span>{t('end')}: </span>
                            <span>{typeof sectorInfoData.startPoint === 'number' && Number(sectorInfoData.startPoint) + (Number(sectorInfoData.length) / 1000)}</span>
                        </div>
                        {
                            zoom > 11 && sectorId &&
                            <div>
                                <span>{t('kilometer')}:</span>
                                <span>{sectorInfoData.kilo} км</span>
                            </div>
                        }

                        <div>
                            <span>{t('total-length')}:</span>
                            <span>{sectorInfoData.length} м</span>
                        </div>
                    </SectorInfoWindow>
                </InfoWindow>

            </Map>
            <MapAttrGroup status={attrStatus}>
                <MapAttrGroupTitle onClick={() => setAttrStatus(!attrStatus)}>
                    {
                        categoryName ? categoryName : t('info')
                    }
                    {
                        attrStatus ? <ExpandSvg/> : <CollapseSvg/>
                    }
                </MapAttrGroupTitle>
                <MapAttrGroupItems>
                    {
                        isDataPending.$attributeList.data && isDataPending.$attributeList.data.length > 0 &&
                        isDataPending.$attributeList.data.map(item =>
                            <NavLink
                                key={item.id}
                                to={`/?attributeId=${item.id}${urlParams ? '&' + urlParams : ''}`}
                                isActive={() => {return Number(attributeId) === Number(item.id)}}
                            >
                                {item.name}
                            </NavLink>
                        )
                    }
                </MapAttrGroupItems>
            </MapAttrGroup>
        </MapContainer>

    )
};

export const GoogleMapComponent = GoogleApiWrapper({
    apiKey: 'AIzaSyD5UhFY6pkdScRx8KcvTUu5bNFJ8CEtKRQ',
    language: 'ru',
})(GoogleMapComponentView);