import React from 'react'
import {ReportListTabItem, ReportListTabs} from "../atoms";
import ScrollMenu from 'react-horizontal-scrolling-menu';

export const ReportListTab = ({showIndex, setShowIndex, data}) => {

    return (
        <ReportListTabs>
            <ScrollMenu
                alignCenter={false}
                hideArrows
                itemsCount={data.length}
                translate={0}
                wheel
                dragging
                transition={0.3}
                data={data.map((item, idx) => (
                    <ReportListTabItem
                        key={`${idx + 1}`}
                        onClick={() => setShowIndex(idx)}
                        active={showIndex === idx}
                    >
                        {item.name}
                    </ReportListTabItem>
                ))}
            />
        </ReportListTabs>
    )
}