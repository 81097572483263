import {createEvent} from "effector";
import {
    fetchAllOrgInfo,
    fetchSummary,
    fetchDetailSummary,
    fetchAllSummary,
    fetchOrganizationInfo,
    fetchOrganizationSector,
    fetchSubSector,
    fetchStationSubSector,
    fetchStation,
    fetchCategory,
    fetchAttribute, fetchOrganizationFullInfo
} from "./effect";

export const allOrgInfoMount = createEvent()
export const summaryMount = createEvent()
export const summaryDetailMount = createEvent()
export const allSummaryMount = createEvent()
export const organizationInfoMount = createEvent()
export const organizationFullInfoMount = createEvent()
export const organizationSectorMount = createEvent()
export const subSectorMount = createEvent()
export const stationSubSectorMount = createEvent()
export const stationMount = createEvent()
export const selectEvent = createEvent()
export const reportCategoryMount = createEvent()
export const reportAttributeMount = createEvent()
export const changeCommonReportSlideDown = createEvent()
export const removeCommonReportSlideDownId = createEvent()
export const clearCommonReport = createEvent()
export const generateDetailThead = createEvent()
export const generateDetailTbody = createEvent()
export const initDetailReport = createEvent()
export const clearSelected = createEvent()
export const clearAttribute = createEvent()
export const clearStation = createEvent()
export const clearSubSector = createEvent()
export const clearStationSector = createEvent()
export const subSectorFromContext = createEvent()
export const stationFromContext = createEvent()
export const sectorStationFromContext = createEvent()
export const sectorFromStation = createEvent()
export const detailForceLoading = createEvent()

allOrgInfoMount.watch(fetchAllOrgInfo)
summaryMount.watch(fetchSummary)
summaryDetailMount.watch(fetchDetailSummary)
allSummaryMount.watch(fetchAllSummary)
organizationInfoMount.watch(fetchOrganizationInfo)
organizationSectorMount.watch(fetchOrganizationSector)
subSectorMount.watch(fetchSubSector)
stationSubSectorMount.watch(fetchStationSubSector)
stationMount.watch(fetchStation)
reportCategoryMount.watch(fetchCategory)
reportAttributeMount.watch(fetchAttribute)
organizationFullInfoMount.watch(fetchOrganizationFullInfo)