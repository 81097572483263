import React from 'react'
import {ReportFormHeader} from "./report-form-header";
import {DetailTab, ReportContainer} from "../atoms";
import {useOrgReport} from "../../../hooks/info";
import {Checkbox, Col, Row} from "antd";
import {ReportFormField} from "../organisms/report-form-field";
import {LISTS} from "../../../constants/common";
import {useStore} from "effector-react";
import {$report, selectEvent} from "../../../models/report-model";
import {useTranslation} from "react-i18next";
import {InputSystem} from "../../../ui/molecules";
import {Button, GridColumn} from "../../../ui/atoms";

export const OrganizationReportForm = () => {
    const {date, changeDate, formHandleSelect, onSubmit} = useOrgReport()
    const {$selectedIds} = useStore($report)
    const {t} = useTranslation()
    const {
        showForm,
        showDetail,
        station,
        startValue,
        endValue,
        sector,
        category,
        sub_sector,
        station_sector
    } = $selectedIds



    return (
        <ReportContainer>
            <ReportFormHeader
                dateValue={date}
                changeDate={changeDate}
            />
            <GridColumn column={3} gap={0}>
                <DetailTab active={showForm === 'common'} onClick={() => formHandleSelect('common')}>
                    {t('total')}
                </DetailTab>
                <DetailTab active={showForm === 'station'} onClick={() => formHandleSelect('station')}>
                    {t('station')}
                </DetailTab>
                <DetailTab
                    active={showForm === 'station-sector'}
                    onClick={() => formHandleSelect('station-sector')}
                >
                    {t('station-driving')}
                </DetailTab>
            </GridColumn>
            <Row gutter={24}>
                <Col span={showForm === 'station' ? 12 : 8}>
                    <ReportFormField
                        placeholder={t('select-direction')}
                        list={LISTS.SECTOR}
                        label={t('direction')}
                    />
                </Col>
                {
                    (showForm === 'station' || showForm === 'station-sector') ? (
                            <>
                                <Col span={showForm === 'station' ? 12 : 8}>
                                    <ReportFormField
                                        placeholder={t('select-station')}
                                        list={LISTS.STATION}
                                        label={t('station')}
                                        disabled={Object.values(sector).length === 0}
                                    />
                                </Col>
                                {
                                    showForm === 'station-sector'
                                    && (
                                        <Col span={8}>
                                            <ReportFormField
                                                placeholder={t('select-station-driving')}
                                                list={LISTS.STATION_SUB_SECTOR}
                                                label={t('station-driving')}
                                                disabled={(Object.values(station).length === 0
                                                    || Object.values(sector).length === 0)}
                                            />
                                        </Col>
                                    )
                                }
                            </>
                        )
                        : (
                            <Col span={8}>
                                <ReportFormField
                                    placeholder={t('select-driving')}
                                    disabled={Object.values(sector).length === 0}
                                    list={LISTS.SUB_SECTOR}
                                    label={t('driving')}
                                />
                            </Col>
                        )
                }
                <Col span={(showForm === 'station' || showForm === 'station-sector') ? 12 : 8}>
                    <ReportFormField
                        placeholder={t('select-category')}
                        list={LISTS.CATEGORY}
                        label={t('category')}
                    />
                </Col>
                <Col span={(showForm === 'station' || showForm === 'station-sector') ? 12 : 8}>
                    <ReportFormField
                        placeholder={t('select-sub-category')}
                        list={LISTS.ATTRIBUTE}
                        label={t('attribute')}
                        disabled={Object.values(category).length === 0}
                    />
                </Col>
                {
                    (showForm === 'common' || showForm === 'station-sector')
                    && (Object.keys(sub_sector).length === 1 || Object.keys(station_sector).length > 0)
                    && (
                        <>
                            <Col span={showForm === 'station-sector' ? 12 : 8}>
                                <InputSystem
                                    value={startValue}
                                    change={(e) => selectEvent({startValue: e})}
                                    type='number'
                                    placeholder={t('change-value')}
                                    label={t('from')}
                                />
                            </Col>
                            <Col span={showForm === 'station-sector' ? 12 : 8}>
                                <InputSystem
                                    value={endValue}
                                    change={(e) => selectEvent({endValue: e})}
                                    type='number'
                                    placeholder={t('change-value')}
                                    label={t('to')}
                                />
                            </Col>
                        </>
                    )
                }
            </Row>
            <Row type='flex' style={{marginTop: 'auto', justifyContent: 'flex-end', alignItems: 'center'}}>
                {showForm !== 'station' && (Object.keys(sub_sector).length === 1 || Object.keys(station_sector).length > 0)
                && (
                    <Checkbox
                        checked={showDetail}
                        onChange={(e) => selectEvent({showDetail: e.target.checked})}>
                        {t('detail')}
                    </Checkbox>
                )}
                <Button onClick={onSubmit}>{t('show')}</Button>
            </Row>
        </ReportContainer>
    )
}