import moment from 'moment';
import React from 'react'
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {useUrlParams} from '../../../hooks/common';
import {ArowLeft, PrinterSvg} from '../../../media';
import {NoBorderButton, NoBorderDatePicker} from '../../dashboard/atoms';
import {DetailInfoHeading, InfoTitle} from '../atoms';
import {useStore} from "effector-react";
import {$report} from "../../../models/report-model";

export const ReportFormHeader = ({dateValue, changeDate}) => {
    const {$selectedIds} = useStore($report)
    const {t} = useTranslation();
    const {location} = useHistory()
    useUrlParams(location, ['info'])

    return (
        <DetailInfoHeading>
            <InfoTitle>
                {t('detail-info')}
            </InfoTitle>
            <NoBorderDatePicker
                defaultValue={moment(dateValue)}
                onChange={changeDate}
                format='DD/MM/YYYY'
            />
            <NoBorderButton icon={(<PrinterSvg/>)}>
                {t('print')}
            </NoBorderButton>
            <Link className='back-link' to={{
                pathname: '',
                search: $selectedIds.mainPath ? $selectedIds.mainPath : ''
            }}>
                <NoBorderButton icon={(<ArowLeft/>)}>
                    {t('back')}
                </NoBorderButton>
            </Link>
        </DetailInfoHeading>
    )
}