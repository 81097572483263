import React from "react";
import {Row, Col} from "antd";
import {Header} from "../../components/header";
import {MainContainer} from "./page";
import {DashboardContainer} from "./atoms";
import {useHistory} from "react-router-dom";
import ReactGA from "react-ga";

export const Dashboard = () => {
    const locale = useHistory();
    ReactGA.pageview(locale.location.pathname + locale.location.search);

    return (
        <DashboardContainer>
            <Row type='flex'>
                <Col span={24}>
                    <Header/>
                </Col>
                <MainContainer/>
            </Row>
        </DashboardContainer>
    );
};
