import React, {useState} from 'react';
import {withRouter} from "react-router-dom";
import Cookies from 'js-cookie';
import {withTheme} from "styled-components";
import {Button, LinkCustom, Section} from "../../../ui/atoms";
import {ButtonGroup, FormBlock, SignInBlock} from "../atoms";
import {LogoAisupSvgWhite, PasswordSvg, Person} from "../../../media";
import {InputSystem} from "../../../ui/molecules";
import {getToken} from "../api";
import {openNotificationWithIcon} from "../../../helpers";


const SignInFormView = (props) => {
    //status
    const [ loading, setLoading ] = useState(false);

    //data
    const [ login, setLogin ] = useState(false);
    const [ password, setPassword ] = useState(false);

    const authUser = (e) => {
        e.preventDefault();
        Cookies.remove('token');
        let data = {
            login: login,
            password: password
        };
        setLoading(true);
        getToken(data)
            .then(response => {
                if(response.status === 200) {
                    setLoading(false);
                    Cookies.set('token', response.data);
                    openNotificationWithIcon('success', 'Уcпешно', 'Вы успешно авторизовались.');
                    props.history.push('/');
                }
            })
            .catch(error => {
                setLoading(false);
                if (error.status === 401){
                    openNotificationWithIcon('error', 'Ошибка', 'Неверный логин или пароль.');
                } else{
                    openNotificationWithIcon('error', 'Ошибка', 'Неверный логин или пароль.');
                }
            })

    };
    return (
        <Section>
            <SignInBlock onSubmit={(e) => authUser(e)} background={props.theme.main.brandColor}>
                {/*<LogoSvg/>*/}
                <LogoAisupSvgWhite/>
                <FormBlock>
                    <InputSystem
                        disabled={loading}
                        noUpperCase
                        change={setLogin}
                        icon={<Person/>}
                        placeholder='Введите логин'
                    />
                    <InputSystem
                        disabled={loading}
                        noUpperCase
                        change={setPassword}
                        icon={<PasswordSvg/>}
                        type='password'
                        placeholder='Введите пароль'
                    />
                    <ButtonGroup>
                        <LinkCustom to='/restore'>Забыли пароль?</LinkCustom>
                        <Button htmlType="submit" loading={loading}>Войти</Button>
                    </ButtonGroup>
                </FormBlock>
            </SignInBlock>
        </Section>
    )
};

export const SignInForm = withTheme(withRouter(SignInFormView));
