import styled, {keyframes} from 'styled-components';
import {Svg} from "../../../ui/atoms";
import {Menu} from "antd";

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0 1px 0 #EDF1F7;
  width: 100%;
  border-bottom: 1px solid #EDF1F7;
  padding: 0 32px;
  min-height: 80px;
  position: fixed;
  top:0;
  left: 0;
  z-index: 30;
  
  @media (max-width: 1440px) {
    height: 50px;
    min-height: 50px;
    padding: 0 16px;
  }
`;
export const HeaderLeft = styled.div`
  display: flex;
  width: 150px;
  align-items: flex-start;
  justify-content: flex-start;
  
  svg {
    @media (max-width: 1440px){
      width:65px;
      height: 30px
    }
  }
`;
export const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 640px;
  width: 100%;
`;
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
    ${Svg}{
    margin-right: 26px;
  }
  
`;
export const UserName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: inline-flex;
  flex-direction: column;
  color: #222B45;
  justify-content: center;
  align-items: flex-end;
  margin-right: 16px;
  span{
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #8F9BB3;
  }
`;
export const HeaderAvatarBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.main.semanticColor.basic[500]};
  
  img{
    max-width: 100%; 
    height: auto;
    object-fit: contain;
  }
  
  @media (max-width: 1440px) {
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
  }
`;

export const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer
`

export const MenuList = styled(Menu)`
  padding: 8px 0 !important;
  border-radius: 4px;
  min-width: 250px;
`

export const ProfileMenuItem = styled(Menu.Item)`
  padding: 12px 24px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  color: ${({theme}) => theme.main.semanticColor.basic[800]} !important;
  display: flex !important;
  align-items: center !important;
  transition: .2s ease;

  &:last-child {
    border-top: 1px solid #f0f0f0;
  }
  
  &:first-child {
    border-top: 0;
  }
  
  &:hover {
    background: none !important;
    color: ${({theme}) => theme.main.semanticColor.primary[500]} !important;
  }
  
  &:hover svg {
    color: ${({theme}) => theme.main.semanticColor.primary[500]};
  }
  
  svg {
    margin-right: 10px;
    color: ${({theme}) => theme.main.semanticColor.basic[600]};
    transition: .2s ease
  }
`

export const NotificationWrapper = styled.div`
  min-width: 370px;
  max-width: 370px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: ${({theme}) => theme.main.themeColor[100]};
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.main.semanticColor.basic[300]};
`

export const NotificationHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${({theme}) => theme.main.semanticColor.basic[300]};
  
  h4{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${({theme}) => theme.main.semanticColor.basic[800]}
  }
  
  svg {
    color: ${({theme}) => theme.main.semanticColor.basic[700]}
  }
`

export const AvatarUploadSection = styled.div`
  display:flex;
  justify-content: center;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 96px;
    min-height: 96px;
    max-width: 96px;
    max-height: 96px;
    background: #FAFAFB;
    border: 1px solid #E4E9F2;
    box-sizing: border-box;
    border-radius: 50%;
    position: relative;
  }
  
  img{
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    object-fit: contain;
  }
  svg{
    width: 48px;
    height: 48px;
    &:nth-child(2){
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 32px;
      height: 32px;
    }
  }
`;

export const ModalViewTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: ${({theme}) => theme.main.semanticColor.basic[600]};
  margin-bottom: 24px
`

export const NotificationListMenu = styled.div`
  overflow-y: auto;
  max-height: 280px;
  scrollbar-color: ${({theme}) => theme.main.semanticColor.basic[600]};
  scrollbar-width: thin;
  margin-right: 8px;
  
  &::-webkit-scrollbar {
    width: 6px;
}

&::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({theme}) => theme.main.semanticColor.basic[600]};
}
`

export const NotificationItem = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 16px;
  padding: 8px 42px 8px 24px;
  font-size: 14px;
  font-weight: 600
  
  img{
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  
  div {
    display: flex;
    flex-direction: column
  }
  
  p {
    color: ${({theme}) => theme.main.semanticColor.basic[800]};
  }
  
  span {
    color: ${({theme}) => theme.main.semanticColor.basic[600]};
  }
`

const fadeIn = keyframes`
from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(0,0,0, .2);
  left: 0;
  top: 0; 
  z-index: 25;
  display: none;
  animation: ${fadeIn} .3s;
  @media (max-width: 1280px) {
    display: ${({visible}) => visible ? 'block' : 'none'};
    opacity: 1;
  }
`