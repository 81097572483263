import {combine, createStore} from "effector";
import {fetchPositionList, fetchUserInfo} from './effects';

export const $users = createStore([])
    .on(fetchUserInfo.done, (_, response) => response.result.data);

export const $permissions = createStore({role: null})
    .on(fetchUserInfo.done, (params, response) => {
        return {
            ...params,
            role: response.result.data.length > 0 ? response.result.data.roles.join(',') : 'admin'
        }
    })


export const $positionList = createStore({loading:false, data:[], error:false})
      .on(fetchPositionList.pending, (params, pending) => {
          return {
              ...params,
              loading: pending
          }
      })
      .on(fetchPositionList.finally,  (params, response) => {
          if (response.error) {
              return {
                  ...params,
                  error: response.error.response
              }
          }else {
              return {
                  ...params,
                  data: response.result.data,
                  filtered: response.result.data.map(item => ({
                      value: item.id,
                      label: item.title
                  })),
                  error: false
              }
          }
      })

export const $isDataPending = combine({
    $users,
    $positionList
});
