import {Checkbox, Col, Row} from 'antd'
import React from 'react'
import {useAllReport} from '../../../hooks/info'
import {ReportContainer} from '../atoms'
import {ReportFormHeader} from './report-form-header'
import {useTranslation} from 'react-i18next'
import {useStore} from 'effector-react'
import {LISTS} from '../../../constants/common'
import {ReportFormField} from "../organisms/report-form-field";
import {$report} from "../../../models/report-model";
import {Button} from "../../../ui/atoms";

export const AllReportForm = () => {
    const {date, changeDate, onSubmit} = useAllReport()
    const {t} = useTranslation()
    const {$selectedIds} = useStore($report)

    return (
        <ReportContainer>
            <ReportFormHeader
                dateValue={date}
                changeDate={changeDate}
            />
            <Row gutter={24}>
                <Col span={12}>
                    <ReportFormField
                        placeholder={t('select-category')}
                        list={LISTS.CATEGORY}
                        label={t('category')}
                    />
                </Col>
                <Col span={12}>
                    <ReportFormField
                        placeholder={t('select-sub-category')}
                        list={LISTS.ATTRIBUTE}
                        label={t('attribute')}
                        disabled={Object.values($selectedIds.category).length === 0}
                    />
                </Col>
            </Row>
            <Row type='flex' style={{marginTop: 'auto', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button onClick={onSubmit}>{t('show')}</Button>
            </Row>
        </ReportContainer>
    )
}