import React from "react";
import {DashboardHeadSection} from "../atoms";
import {DashboardHead, Content} from "../organisms";
import {SectorsHooks, UseOrgCarousel} from "../../../hooks/dashboard";

export const MainContainer = () => {

    UseOrgCarousel();
    SectorsHooks();

    return (
        <DashboardHeadSection>
            <DashboardHead/>
            <Content/>
        </DashboardHeadSection>
    );
};