import React, {useEffect} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {NavLinksBlock} from "../atoms";
import {SubSectorsHooks} from "../../../hooks/dashboard";
import {useUrlParams} from "../../../hooks/common";
import {stationSubsMount} from "../../../models/station-model";
import {useStore} from "effector-react";
import {$organization} from "../../../models/organization-model";


export const MenuItem = (props) => {
    const {
        subSectors,
        id
    } = props;

    const {subSectorId, urlParams} = SubSectorsHooks(props);

    return (
        <NavLinksBlock>
            {
                subSectors && subSectors.map(item => item && item.id && !item.secondaryStage &&
                    <NavLink
                        exact
                        to={{
                            pathname: '/',
                            search: `subSectorId=${item.id}&sectorId=${id}${urlParams ? '&' + urlParams : ''}`
                        }}
                        key={item.key}
                        isActive={() => {
                            return Number(subSectorId) === Number(item.id)
                        }}
                    >
                        {item.label}
                    </NavLink>
                )
            }
        </NavLinksBlock>
    )
};

export const MenuItemStation = (props) => {
    const {
        subSectors,
        id
    } = props;
    const location = useLocation()
    const organization = useStore($organization)
    const {subSectorId, urlParams, sectorId} = useUrlParams(location, ['subSectorId', 'sectorPoint']);


    useEffect(() => {
        if (sectorId && organization.$organizationsSectorsList.data && organization.$organizationsSectorsList.data.length > 0) {
            const dataFilter = organization.$organizationsSectorsList.data.filter(item => item.id === Number(sectorId))
            if (dataFilter.length > 0) {
                stationSubsMount(dataFilter[0].subSectors)
            }
        }
    }, [sectorId, organization.$organizationsSectorsList.data])

    // const {subSectorId, urlParams} = SubSectorsHooks(props);


    return (
        <NavLinksBlock>
            {
                subSectors && subSectors.map(item => item && item.id &&
                    <NavLink
                        exact
                        to={{
                            pathname: '/',
                            search: `subSectorId=${item.id}${urlParams ? '&' + urlParams : ''}`
                        }}
                        key={item.key}
                        isActive={() => {
                            return Number(subSectorId) === Number(item.id)
                        }}
                    >
                        {item.label}
                    </NavLink>
                )
            }
        </NavLinksBlock>
    )
};