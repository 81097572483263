import {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useUrlParams} from "../common";
import {stringToArray} from "../../helpers";
import {useStore} from "effector-react";
import {
    $report,
    clearAttribute,
    clearStation,
    clearStationSector,
    clearSubSector,
    reportAttributeMount,
    sectorStationFromContext,
    selectEvent,
    stationFromContext,
    stationMount,
    stationSubSectorMount,
    subSectorFromContext,
    subSectorMount
} from "../../models/report-model";
import {LISTS, URL_ID} from "../../constants/common";
import {getSector} from "../../api/sector-api";
import {getAttributeInfo, getCategoryInfo} from "../../api/category-api";
import {getStation} from "../../api/station-api";
import {generateQueryParams, getEnd, getStart, isAdmin, isEmployee} from "./helper";
import {$organization} from "../../models/organization-model";
import {$userModel} from "../../models/user-model";

const initialParams = {
    length: 20,
    start: 0,
}

export const useReportForm = ({list}) => {
    const {location, push} = useHistory()
    const {sectorId, subSectorId, categoryId, attributeId, organizationId, stationId} = useUrlParams(location)
    const [statusModal, setStatusModal] = useState(false)
    const {$selectedIds} = useStore($report)
    const {$organizationsSectorsList} = useStore($organization)
    const {$userRole} = useStore($userModel)

    const getSubSectorList = useCallback((id) => {
        const context = $organizationsSectorsList.data
        if (isAdmin($userRole)) {
            const params = {
                params: {
                    ...initialParams
                },
                id,
                clear: true,
            }
            subSectorMount(params)
        }

        if (isEmployee($userRole)) {
            const contextSector = context.find(item => item.id === id) || false
            if (contextSector) {
                const data = []
                const contextSubSector = contextSector && contextSector.subSectors
                for (let i = 0; i < contextSubSector.length; i++) {
                    if (!contextSubSector[i].secondaryStage) {
                        data.push(contextSubSector[i])
                    }
                }
                subSectorFromContext({[id]: data})
            }
        }
    }, [$userRole, $organizationsSectorsList.data])

    const getStationList = useCallback((id) => {
        const context = $organizationsSectorsList.data
        if (isAdmin($userRole)) {
            stationMount(id)
        }

        if (isEmployee($userRole)) {
            const contextSector = context.find(item => item.id === id) || false
            if (contextSector) {
                const data = []
                const contextSubSector = contextSector && contextSector.subSectors
                for (let i = 0; i < contextSubSector.length; i++) {
                    if (contextSubSector[i].station && contextSubSector[i].station.parent.id === id) {
                        if (data.findIndex(item => item.id === contextSubSector[i].station.id) === -1) {
                            data.push(contextSubSector[i].station)
                        }
                    }
                }
                stationFromContext(data)
            }
        }

    }, [$userRole, $organizationsSectorsList.data])

    const getStationSectorList = useCallback((id, parentId) => {
        const context = $organizationsSectorsList.data
        if (isAdmin($userRole)) {
            stationSubSectorMount(id)
        }

        if (isEmployee($userRole)) {
            const contextSector = context.find(item => item.id === Number(parentId)) || false
            if (contextSector) {
                const data = []
                const contextSubSector = contextSector && contextSector.subSectors
                for (let i = 0; i < contextSubSector.length; i++) {
                    if (contextSubSector[i].secondaryStage && contextSubSector[i].station.id === id) {
                        data.push(contextSubSector[i])
                    }
                }
                sectorStationFromContext(data)
            }
        }
    }, [$userRole, $organizationsSectorsList.data])

    const getAttributeList = useCallback((id) => {
        reportAttributeMount(id)
    }, [])

    const getSectors = useCallback(() => {
        if (sectorId) {
            const ids = stringToArray(sectorId, ',')
            getStationList(ids[0])
            for (let i = 0; i < ids.length; i++) {
                getSubSectorList(ids[i])
                getSector(ids[i])
                    .then((res) => {
                        const data = {}
                        data[ids[i]] = res.data
                        selectEvent({key: 'sector', data})
                    })
            }
        } else {
            selectEvent({sector: {}})
            clearSubSector()
            clearStation()
        }
    }, [sectorId, getStationList, getSubSectorList])

    const getSubSectors = useCallback(() => {
        if (subSectorId) {
            const ids = stringToArray(subSectorId, ',')
            for (let i = 0; i < ids.length; i++) {
                getSector(ids[i])
                    .then((res) => {
                        const data = {}
                        data[ids[i]] = res.data
                        const startValue = getStart(res.data)
                        const endValue = getEnd(res.data)
                        if (res.data.secondaryStage) {
                            selectEvent({key: 'station_sector', data})
                            selectEvent({startValue})
                            selectEvent({endValue})
                            selectEvent({showDetail: true})

                            if ($selectedIds.showForm !== 'station-sector' && !$selectedIds.mounted) {
                                selectEvent({showForm: 'station-sector'})
                                selectEvent({mounted: true})
                            }
                        } else {
                            if (ids.length === 1) {
                                selectEvent({startValue})
                                selectEvent({endValue})
                                selectEvent({mounted: true})
                            }
                            selectEvent({key: 'sub_sector', data})
                        }
                    })
            }
        } else {
            selectEvent({sub_sector: {}})
            selectEvent({station_sector: {}})
        }
    }, [subSectorId, $selectedIds.showForm, $selectedIds.mounted])

    const getCategories = useCallback(() => {
        if (categoryId) {
            const ids = stringToArray(categoryId, ',')
            for (let i = 0; i < ids.length; i++) {
                getAttributeList(ids[i])
                getCategoryInfo(ids[i])
                    .then((res) => {
                        const data = {}
                        data[ids[i]] = res.data
                        selectEvent({key: 'category', data})
                    })
            }
        } else {
            selectEvent({category: {}})
            clearAttribute()
        }
    }, [categoryId, getAttributeList])

    const getAttributes = useCallback(() => {
        if (attributeId) {
            const ids = stringToArray(attributeId, ',')
            for (let i = 0; i < ids.length; i++) {
                getAttributeInfo(ids[i])
                    .then((res) => {
                        const data = {}
                        data[ids[i]] = res.data
                        selectEvent({key: 'attribute', data})
                    })
            }
        } else {
            selectEvent({attribute: {}})
        }
    }, [attributeId])

    const getStations = useCallback(() => {
        if (stationId) {
            const ids = stringToArray(stationId, ',')

            getStation(ids[0])
                .then((res) => {
                    const data = {}
                    data[ids[0]] = res.data
                    getStationSectorList(ids[0], res.data.parent.id)
                    selectEvent({key: 'station', data})
                })
        } else {
            selectEvent({station: {}})
            clearStationSector()
        }
    }, [stationId, getStationSectorList])

    useEffect(() => {
        if (list === LISTS.SECTOR) {
            getSectors()
        }
    }, [list, getSectors])

    useEffect(() => {
        if (list === LISTS.SUB_SECTOR) {
            getSubSectors()
        }
    }, [list, getSubSectors])

    useEffect(() => {
        if (list === LISTS.CATEGORY) {
            getCategories()
        }
    }, [list, getCategories])

    useEffect(() => {
        if (list === LISTS.ATTRIBUTE) {
            getAttributes()
        }
    }, [list, getAttributes])

    useEffect(() => {
        if (list === LISTS.STATION) {
            getStations()
        }
    }, [list, getStations])

    const handleAccept = (data) => {
        const {sector, station, station_sector, sub_sector, category, attribute} = data
        const allData = {
            sector,
            station,
            station_sector,
            sub_sector,
            category,
            attribute,
        }

        selectEvent(data)
        setStatusModal(false)
        const queryParams = generateQueryParams(allData)
        push({
            pathname: '/',
            search: `info${organizationId
                ? `&${URL_ID.ORGANIZATION}=${organizationId}`
                : ''}${queryParams ? `&${queryParams}` : ''}`
        })
    }

    return {
        statusModal,
        setStatusModal,
        handleAccept
    }
}