import React, {useState, useEffect, useCallback} from 'react'
import {ReportInfiniteScroll, ReportListWrapper, ReportModalTitle, ReportModalWrapper} from "../atoms";
import {Button, ModalFooterWrapper} from "../../../ui/atoms";
import {useStore} from "effector-react";
import {$report, removeCommonReportSlideDownId, reportCategoryMount} from "../../../models/report-model";
import {ReportListsItem} from "./report-list-item";
import {matchByParent} from "../../../hooks/info/helper";
import {useTranslation} from "react-i18next";

const initialParams = {
    length: 30,
    start: 0
}

export const CategoryModal = ({handleAccept, setStatusModal, title}) => {
    const [selected, setSelected] = useState(null)
    const {$selectedIds, $reportCategoryList} = useStore($report)
    const {data, result} = $reportCategoryList
    const [start, setStart] = useState(0)
    const {t} = useTranslation()

    useEffect(() => {
        if (Object.values($selectedIds.category).length > 0) {
            setSelected($selectedIds.category)
        }else {
            setSelected(null)
        }
    }, [$selectedIds.category])

    const getCategoryList = useCallback((params) => {
        reportCategoryMount(params)
    }, [])


    const handleClick = () => {
        const data = {...selected}
        removeCommonReportSlideDownId(data)
        const {attribute} = $selectedIds
        const result = matchByParent({attribute}, data)
        handleAccept({...$selectedIds, ...result, category: {...data}})
    }

    const load = (page) => {
        const params = {
            ...initialParams,
            start: 30 * page,
        }
        setStart(page)
        getCategoryList(params)
    }

    return (
        <ReportModalWrapper>
            <ReportModalTitle>
                {title}
            </ReportModalTitle>
            {
                data.length > 0
                && (
                    <ReportListWrapper>
                        <ReportInfiniteScroll
                            pageStart={start}
                            loadMore={load}
                            hasMore={(result.recordsFiltered - (start * 20)) > 20 }
                            loader={<div className="loader" key={0}>Loading ...</div>}
                            useWindow={false}
                            threshold={400}
                        >
                            {
                                data.map(item => (
                                    <ReportListsItem
                                        key={item.id}
                                        item={item}
                                        setSelected={setSelected}
                                        selected={selected}
                                        multipleSelect
                                    />
                                ))
                            }
                        </ReportInfiniteScroll>
                    </ReportListWrapper>
                )
            }

            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button onClick={handleClick}>
                    {t('apply')}
                </Button>
            </ModalFooterWrapper>
        </ReportModalWrapper>
    )
}