import styled from "styled-components";
import Carousel from "nuka-carousel";
import {NavLink} from "react-router-dom";

export const CarouselUI = styled(Carousel)`
  display: flex;
  outline: none;
  .slider-control-bottomcenter {
    display: none;
  }
  .slider-control-centerleft button,
  .slider-control-centerright button {
    border: none;
    box-shadow: none;
    background: transparent;
  }
  .slider-frame{
    margin: 20px 30px !important;
  }
  @media(max-width: 1400px){
    .slider-frame{
      margin: 10px 30px !important;
    }
  }
`;

export const CarouselNavLink = styled(NavLink)`
  padding: 8px 12px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    width: 100%;
    background: ${({theme}) => (theme.main.semanticColor.basic[300])};
    color: ${({theme}) => (theme.main.semanticColor.basic[700])};
    border-radius: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 48px;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    transition: .3s;
  }
  &:hover, &.active {
    span{
      color: ${({theme}) => (theme.main.themeColor[100])};
      background: ${({theme}) => (theme.main.semanticColor.primary[500])};
      box-shadow: 0 4px 8px rgba(51, 102, 255, 0.24);
    }
  }
  @media (max-width: 1400px){
    height: 62px;
    span{
      font-size: 14px;
      line-height: 15px;
    }
  }
    
`;
export const HintText = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${({theme}) => theme.main.semanticColor.primary[500]}
    margin-top: 8px
`;

export const MapContainer = styled.div`
  display: flex;
  position: relative;
  height: calc(100vh - 254px);
  width: 100%;
  flex-direction: column;
  margin: 0 0 15px;
  border-radius: 8px;
  border: 1px solid #EDF1F7;
  overflow: hidden;
`;

export const TabCustom = styled.div`
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    height: 56px;
  }
  .tab-link{
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 24px;
  }
  @media(max-width: 1400px){
    .tab-link{
      height: 46px;
    }
    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
      height: 46px;
    }
  }
`;

export const GetImgStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
`;

export const SectorInfoWindow = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  div{
  margin-bottom: 16px;
    span{
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #8F9BB3;
      &:nth-child(2){
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #222B45;
        text-transform: initial;
        margin-left: 5px;
      }
    }
  }
`;

export const JournalGroup = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  max-height: 70vh;
  margin-bottom: 2vh;
`;


export const MapAttrGroupTitle = styled.div`
  color: #222B45;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  padding: 12px 23px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 1400px){
    font-size: 14px;
    line-height: 15px;
    padding: 10px 23px;
  }
`;

export const MapAttrGroupItems = styled.div`
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #222B45;
  display: flex;
  flex-direction: column;
  a{
    //color: #222B45;
    color: #8F9BB3;
    padding: 6px 27px;
    &.active{
      color: #1890ff;
    }
    &:nth-child(even){
      background: #F6F7F9;
    }
  }
`;

export const MapAttrGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 400px;
  ${MapAttrGroupItems}{
    transition: .3s;
    margin-bottom: ${props => props.status ? 'calc(-100% + 125px)' : '0'};
    opacity: ${props => props.status ? 0 : 1};
  }
`;

export const MapLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: ${props => props.status ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'};
  z-index: 9999;
  transition: .3s;
  visibility: ${props => props.status ? 'visible' : 'hidden'};
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  color: white;
`
