import {useEffect} from "react";
import {mountSubSectorsToParent} from "../../models/organization-model";
import {useUrlParams} from "../../hooks/common";
import {useHistory} from "react-router-dom";


export const SubSectorsHooks = (props) => {
    const {location} = useHistory();
    const {id, subSectors} = props;
    const {subSectorId, urlParams} = useUrlParams(location, ['subSectorId', 'sectorId', 'sectorPoint']);

    useEffect(() => {
        if (subSectors && subSectors.length > 0){
            // return null
        } else{
            const params = {
                id: id,
                all: true,
                length: 200
            }
            mountSubSectorsToParent(params)
        }
    }, [id]);
    return {
        subSectorId,
        urlParams
    }
};