import {useCallback, useEffect} from "react";
import {useStore} from "effector-react";
import {useHistory} from "react-router-dom";
import {useUrlParams} from "../common";
import {$organization} from "../../models/organization-model";
import {createSectorLength} from "../../models/organization-model";
import {USER_ROLES} from "../../constants/user";
import {$userModel} from "../../models/user-model";


export const SectorsLengthHook = () => {
    const {location} = useHistory();
    const organization = useStore($organization);
    const {sectorId, subSectorId} = useUrlParams(location, ['sectorPoint']);
    const signedUserInfo = useStore($userModel);


    // const SectorCallback = useCallback((role, data) => {
    //     if (!subSectorId && sectorId) {
    //         if (role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN || role === USER_ROLES.USER) {
    //             const sectorData = data.filter(item => item.id && item.id === Number(sectorId));
    //             if (sectorData && sectorData[0] && sectorData[0].sector) {
    //                 const sectorInfo = {
    //                     start: sectorData[0].startPoint,
    //                     length: sectorData[0].length
    //                 };
    //                 createSectorLength(sectorInfo)
    //             } else if (sectorData && sectorData[0]) {
    //                 const sectorInfo = {
    //                     start: sectorData[0].startPoint,
    //                     length: sectorData[0].length
    //                 };
    //                 createSectorLength(sectorInfo)
    //             }
    //         } else {
    //             const sectorData = data.filter(item => item.sector && item.sector.id && item.sector.id === Number(sectorId))[0];
    //             if (sectorData && sectorData.sector && sectorData.startPoint) {
    //                 const sectorInfo = {
    //                     start: sectorData.startPoint,
    //                     length: sectorData.length
    //                 };
    //                 createSectorLength(sectorInfo)
    //             } else if (sectorData) {
    //                 const sectorInfo = {
    //                     start: sectorData.sector.startPoint,
    //                     length: sectorData.sector.length
    //                 };
    //                 createSectorLength(sectorInfo)
    //             }
    //         }
    //
    //
    //     }
    // }, [sectorId]);

    const SubSectorCallback = useCallback((role, data) => {
        if (subSectorId && data && data.length > 0) {
            const sectorInfo = data.filter(item => item.id && item.id === Number(sectorId))[0];
            if ((role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN || role === USER_ROLES.USER || role === USER_ROLES.BOSS) && sectorInfo.subSector) {
                const sectorData = sectorInfo.subSector.filter(item => item.id === Number(subSectorId))[0];
                if (sectorData && (typeof sectorData.allowedStart) === 'number' && (typeof sectorData.startPoint) === 'number') {
                    const sectorInfo = {
                        start: Math.floor10(sectorData.allowedStart / 1000),
                        length: (sectorData.allowedEnd - sectorData.allowedStart),
                    };
                    createSectorLength(sectorInfo)
                } else if (sectorData && sectorData && (typeof sectorData.startPoint) === 'number') {
                    const sectorInfo = {
                        start: sectorData.startPoint,
                        length: sectorData.length + sectorData.countdownPoint
                    };
                    createSectorLength(sectorInfo)
                }
            } else {
                const sectorData = data.filter(item => item.subSector && item.subSector.id && item.subSector.id === Number(subSectorId))[0];
                if (sectorData && (typeof sectorData.allowedStart) === 'number' && (typeof sectorData.subSector.startPoint) === 'number') {
                    const lengthSum = Math.floor10(sectorData.allowedEnd / 1000) - Math.floor10(sectorData.allowedStart / 1000);
                    const sectorInfo = {
                        start: Math.floor10(sectorData.allowedStart / 1000),
                        length: lengthSum * 1000,
                        allowedStart: sectorData.allowedStart
                    };
                    createSectorLength(sectorInfo)
                } else if (sectorData && sectorData.subSector && (typeof sectorData.subSector.startPoint) === 'number') {
                    const sectorInfo = {
                        start: sectorData.subSector.start,
                        length: sectorData.subSector.length
                    };
                    createSectorLength(sectorInfo)
                }
            }

        }
    }, [subSectorId, sectorId]);

    useEffect(() => {
        if (signedUserInfo.$userRole !== null) {
            if (organization.$organizationsSectorsList.data.length > 0) {
                // SectorCallback(signedUserInfo.$userRole, organization.$organizationsSectorsList.result);
                SubSectorCallback(signedUserInfo.$userRole, organization.$organizationsSectorsList.result);
            }
        }

    }, [signedUserInfo.$userRole, organization.$organizationsSectorsList, SubSectorCallback]);

    return {
        organization
    }
};