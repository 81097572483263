import React, {useEffect, useState} from "react";
import {MainContainer, MobileButton, TabsCustom, TabsHead,} from "../atoms";
import {ArrowLeftSvg, ArrowRightSvg, GridSvg, SectorSvg, TrainSvg} from "../../../media";
import {SectorsList} from "./sectors-list";
import {CategoriesList} from "./categories-list";
import {categoryListMount} from "../../../models/categories-attr-model";
import {useTranslation} from "react-i18next";
import {stationListMount} from "../../../models/station-model";
import {useLocation} from 'react-router-dom';
import {useUrlParams} from "../../../hooks/common";
import {StationList} from "./station-list";

const {TabPane} = TabsCustom;


export const RightSidebar = () => {
    const location = useLocation()
    const {sectorId} = useUrlParams(location);
    const {t} = useTranslation();
    const [status, setStatus] = useState(false);

    useEffect(() => {
        const params = {
            length: 50,
            start: 0
        };
        categoryListMount(params)
    }, []);

    useEffect(() => {
        if (sectorId){
            stationListMount(sectorId)
        }
    }, [sectorId]);

    return (
        <MainContainer status={status}>
            <MobileButton onClick={() => setStatus(!status)}>
                {
                    status ?
                        <ArrowRightSvg/> :
                        <ArrowLeftSvg/>
                }
            </MobileButton>
            <TabsCustom defaultActiveKey="1">
                <TabPane tab={<TabsHead><SectorSvg/><span> {t('directions')}</span></TabsHead>} key="1">
                    <SectorsList/>
                </TabPane>
                <TabPane tab={<TabsHead><TrainSvg/><span> {t('station')}</span></TabsHead>} key="2">
                    <StationList/>
                </TabPane>
                <TabPane tab={<TabsHead><GridSvg/><span> {t('categories')}</span></TabsHead>} key="3">
                    <CategoriesList/>
                </TabPane>
            </TabsCustom>
        </MainContainer>
    );
};

// export const RightSidebar = withRouter(RightSidebarView);
