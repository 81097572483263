import React, { useState, useCallback } from 'react'
import { SignInBlock, FormBlock, ButtonGroup, CountDownWrap } from '../atoms'
import { withTheme } from 'styled-components'
import { Section, LinkCustom, Button, AlertWarning } from '../../../ui/atoms'
import { InputSystem } from '../../../ui/molecules'
import { getOTP, restorePassword } from '../api'
import { Statistic } from 'antd'
import { useHistory } from 'react-router-dom'
import { openNotificationWithIcon } from '../../../helpers'

const { Countdown } = Statistic;

const RestoreFormView = (props) => {
    const { push } = useHistory()
    const [username, setUsername] = useState(undefined)
    const [otp, setOtp] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [showOTP, setShowOTP] = useState(false)
    const [deadline, setDeadLine] = useState(Date.now() + 1000 * 60 * 2)
    const [disabled, setDisabled] = useState(false)

    const GetOtpPassword = useCallback((e) => {
        e.preventDefault()
        setLoading(true)
        getOTP(username)
            .then(res => {
                if (res.status === 200) {
                    setShowOTP(true)
                    setDeadLine(Date.now() + 1000 * 60 * 2)
                    setDisabled(false)
                    setLoading(false)
                }
            }).catch(err => {
                openNotificationWithIcon('error', 'Ошибка', 'Ошибка, попробуйте снова');
                setLoading(false)
            })
    }, [username])

    const changePassword = useCallback((e) => {
        e.preventDefault()
        const params = {
            username: username,
            data: {
                newPassword: password,
                otp: otp
            }
        }

        setLoading(true)

        restorePassword(params)
            .then(res => {
                console.log(res.status)
                if (res.status === 200) {
                    setUsername(undefined)
                    setOtp(undefined)
                    setPassword(undefined)
                    setDisabled(false)
                    setShowOTP(false)
                    push('/sign-in')
                    openNotificationWithIcon('success', 'Уcпешно', 'Вы успешно восстановили профиль.');
                    setLoading(false)
                }

            }).catch(err => {
                setLoading(false)
                openNotificationWithIcon('error', 'Ошибка', 'Ошибка, попробуйте снова');
            })
    }, [username, password, otp, push])


    const countDownFinish = () => {
        setDisabled(true)
    }

    return (
        <Section>
            <SignInBlock background={props.theme.main.brandColor}>
                <FormBlock>
                    {!showOTP
                        ? <InputSystem
                            placeholder='Введите ваш логин '
                            value={username}
                            change={setUsername}
                            type='text'
                        />
                        : <AlertWarning>В течение 2 минут вы получите CMC на телефон. Когда письмо придёт, откройте его и введите полученный код</AlertWarning>
                    }

                    {
                        showOTP &&
                        <>

                            <InputSystem
                                placeholder='Введите код подтверждения'
                                value={otp}
                                change={setOtp}
                                type='text'
                                disabled={disabled}
                            />
                            <InputSystem
                                placeholder='Введите новый пароль'
                                value={password}
                                change={setPassword}
                                type='text'
                                disabled={disabled}
                                style={{ marginBottom: 10 }}
                            />
                            <CountDownWrap>
                                <Countdown value={deadline} onFinish={countDownFinish} />
                                <div onClick={GetOtpPassword}>отправить повторно</div>
                            </CountDownWrap>

                        </>
                    }

                    <ButtonGroup>
                        <LinkCustom to='/sign-in'>Войти</LinkCustom>
                        {showOTP
                            ? <Button
                                htmlType="submit"
                                loading={loading}
                                disabled={!otp || !password}
                                onClick={changePassword}
                            >
                                Сохранить
                            </Button>
                            : <Button
                                htmlType="submit"
                                loading={loading}
                                disabled={!username}
                                onClick={GetOtpPassword}
                            >
                                Получить код
                            </Button>
                        }

                    </ButtonGroup>
                </FormBlock>
            </SignInBlock>
        </Section>
    )
}


export const RestoreForm = withTheme(RestoreFormView)
