import { combine, createStore } from "effector";
import { fetchAttrCategoryList, fetchCategoryList } from "./effects";
import { attrCategoryMount, changeAttrList } from "./events";

const $dashboardCategoriesList = createStore({ loading: false, data: [], error: false })
    .on(fetchCategoryList.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchCategoryList.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            return {
                ...state,
                error: false,
                data: response.result.data,
                result: response.result.data.data.map(item => ({
                    label: item.name,
                    value: item.id
                }))
            }
        }
    });

const $dashboardAttrList = createStore({ loading: false, data: [], error: false })
    .on(attrCategoryMount, (state, response) => {
        return {
            ...state,
            error: false,
            data: [
                ...state.data,
                {
                    id: response
                }
            ]
        }
    })
    .on(fetchAttrCategoryList.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            return {
                ...state,
                data: state.data.map(item => item.id === response.params ? { ...item, data: response.result.data } : item)
            }
        }
    });

const $attributeList = createStore({ loading: false, data: [], result: {}, error: false })
    .on(fetchAttrCategoryList.pending, (state, pending) => ({ ...state, loading: pending }))
    .on(fetchAttrCategoryList.fail, (state, error) => ({ ...state, data: {}, error: error.response }))
    .on(fetchAttrCategoryList.finally, (state, res) => {
        const newData = state.result

        newData[res.params] = res.result.data.map(item => ({
            label: item.name,
            value: item.id,
            parentId: res.params
        }))

        const tmp = []
        for (let i in newData) {
            if (newData[i]) {
                tmp.push(...newData[i])
            }
        }

        return {
            ...state,
            data: tmp,
            result: newData
        }
    })
    .on(changeAttrList, (state, ids) => {
        const newData = {}
        const tmp = []

        for (let i = 0; i < ids.length; i++) {
            newData[ids[i]] = state.result[ids[i]]
        }

        for (let i in newData) {
            if (newData[i]) {
                tmp.push(...newData[i])
            }
        }


        return {
            ...state,
            result: newData,
            data: tmp
        }
    })


export const $isDataPending = combine({
    $dashboardCategoriesList,
    $dashboardAttrList,
    $attributeList
});
