import {httpGet} from "../api";


export const getStationList = (sectorId) => httpGet({
    url: `/station/list/${sectorId}`
})

export const getStation = (id) => httpGet({
    url: `/station/${id}`
})

export const getStationSector = (id) => httpGet({
    url: `/sector/secondaryStages/${id}`
})