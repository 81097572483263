import styled from "styled-components";
import Carousel from "nuka-carousel";
import {NavLink} from "react-router-dom";

export const CarouselUI = styled(Carousel)`
  display: flex;
  outline: none;
  .slider-control-bottomcenter {
    display: none;
  }
  .slider-control-centerleft button,
  .slider-control-centerright button {
    border: none;
    box-shadow: none;
    background: transparent;
  }
`;

export const SectionCarousel = styled(Carousel)`
  display: flex;
  //background: #ffffff;
  //border: 1px solid #edf1f7;
  border-radius: 8px;
  outline: none !important;
  align-items: center;
  height: initial !important;
  .slider-control-bottomcenter {
    display: none;
  }
  .slider-control-centerleft button,
  .slider-control-centerright button {
    border: none;
    box-shadow: none;
    background: transparent;
  }
  a, span {
    transition: 0.3s;
    background: ${props => (props.active ? "#3366FF" : "#fff")};
    border-radius: 8px;
    height: 44px;
    padding: 0 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => (props.active ? "#FFFFFF" : "#222B45")};
    &:hover, &.active {
      color: #ffffff;
      background: #3366ff;
    }
  }
`;

export const CarouselNavLink = styled(NavLink)`
    transition: .3s;
    background: ${({theme}) => (theme.main.semanticColor.basic[300])};
    color: ${({theme}) => (theme.main.semanticColor.basic[700])};
    border-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 48px;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    &:hover, &.active {
      color: ${({theme}) => (theme.main.themeColor[100])};
      background: ${({theme}) => (theme.main.semanticColor.primary[500])};
    }
`
