import React, {useState, useEffect} from 'react'
import {ReportListWrapper, ReportModalTitle, ReportModalWrapper} from "../atoms";
import {Button, ModalFooterWrapper} from "../../../ui/atoms";
import {useStore} from "effector-react";
import {$report} from "../../../models/report-model";
import {ReportListsItem} from "./report-list-item";
import {useTranslation} from "react-i18next";

export const StationModal = ({title, handleAccept, setStatusModal}) => {
    const {$selectedIds, $reportStationList} = useStore($report)
    const [selected, setSelected] = useState(null)
    const {data} = $reportStationList
    const {t} = useTranslation()

    const handleClick = () => {
        const data = {...selected}
        const isTrue = Object.keys(data).length > 0
            ? Object.values($selectedIds.station_sector)
            .findIndex(item => item.station.id === Number(Object.keys(data)[0])) !== -1
            : false

        handleAccept({
            ...$selectedIds,
            station_sector: isTrue ? {...$selectedIds.station_sector} : {},
            station: {...data}
        })
    }

    useEffect(() => {
        if (Object.values($selectedIds.station).length > 0) {
            setSelected($selectedIds.station)
        }else {
            setSelected(null)
        }
    }, [$selectedIds.station])

    return (
        <ReportModalWrapper>
            <ReportModalTitle>
                {title}
            </ReportModalTitle>

            <ReportListWrapper>

                {
                    data.length > 0 && data.map(item => {
                        return (
                            <ReportListsItem
                                key={item.id}
                                item={item}
                                selected={selected}
                                setSelected={setSelected}
                                multipleSelect={false}
                            >
                            </ReportListsItem>
                        )
                    })
                }
            </ReportListWrapper>

            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    {t('cancel')}
                </Button>
                <Button onClick={handleClick}>
                    {t('apply')}
                </Button>
            </ModalFooterWrapper>
        </ReportModalWrapper>
    )
}