import {httpGet, httpPost} from "../api";

export const getInfoAllOrganization = () => httpGet({
    url: '/report/info/all'
})

export const getSummary = ({data}) => httpPost({
    url: '/report/', data
})

export const getAttributeSummary = ({sectorId, attributeId, from, to, helper}) => httpGet({
    url: `/entry/attribute/list/${attributeId}/${sectorId}/${from}/${to}`, params: {...helper.params}
});

export const getAllSummary = ({data}) => httpPost({
    url: '/report/all', data
})

export const getStationAllSummary = ({id, data}) => httpPost({
    url: `/report/station/${id}`, data
})

export const getFullInfo = (id) => httpGet({
    url: `/report/info/organization/${id}`
})
