import {useCallback} from 'react'
import {updateMe, updatePassword} from "../../components/header/api";
import {notification} from "antd";
import {useUserProfile} from "./user-profile-view";
import {signedUserInfoMount} from '../../models/user-model'
import {sendRequest} from "../../api/user-api";

export const useUserProfileEdit = () => {
    const {setStatusModal, logoutSystem} = useUserProfile();

    const updateUserInfo = useCallback((data) => {
        updateMe(data)
            .then(res => {
                if (res.status === 200) {
                    notification['success']({
                        message: 'Успешно!',
                        description: 'Ваш персональные данные изменены'
                    });
                    setStatusModal(false);
                    signedUserInfoMount()
                }
            })
            .catch(err => {
                console.log(err);
                notification['error']({
                    message: 'Ошибка!',
                    description: 'Ошибка, попробуйте позже'
                });
            })

    }, [setStatusModal]);

    const UpdateUserRequest = useCallback(async (type, value) => {
        const data = {
            action: 'update',
            domain: type,
            value: value
        }

        try {
            const res = await sendRequest(data)
            if (res.status === 200) {
                notification['success']({
                    message: 'Успешно!',
                    description: 'Ваш запрос переден в обработку!'
                });
                setStatusModal(false);
                signedUserInfoMount()
            }
        } catch (err) {
            console.log(err.response)
        }

    }, [setStatusModal])

    const changeUserPassword = (data) => {
        updatePassword(data)
            .then(res => {
                if (res.status === 200) {
                    notification['success']({
                        message: 'Успешно!',
                        description: 'Пароль изменен! Пожалйуста, авторизуйтесь заново'
                    });
                    setStatusModal(false);
                    logoutSystem()
                }
            }).catch(err => {
            console.log(err);
            notification['error']({
                message: 'Ошибка!',
                description: 'Ошибка, попробуйте позже'
            });
        })
    };

    return {updateUserInfo, changeUserPassword, UpdateUserRequest}
};
