import {useCallback, useEffect, useState} from "react"
import {useUrlParams} from "../common";
import {useHistory} from "react-router-dom";
import {
    $report,
    generateDetailTbody,
    generateDetailThead, initDetailReport,
    selectEvent,
    summaryDetailMount,
    summaryMount
} from "../../models/report-model";
import {useStore} from "effector-react";
import {stringToArray} from "../../helpers";
import {generateQueryParams, getInfo, getMatches, objToArray} from "./helper";
import {URL_ID} from "../../constants/common";
import {detailForceLoading} from "../../models/report-model/event";

export const useOrgReport = () => {
    const {location, push} = useHistory()
    const {$selectedIds, $reportSubSectorList, $reportAttributeList} = useStore($report)
    const {stationId, subSectorId, sectorId, organizationId} = useUrlParams(location)
    const [date, setDate] = useState(new Date())

    const {
        showForm,
        showDetail,
        sub_sector,
        attribute,
        sector,
        category,
        startValue,
        endValue,
        station_sector
    } = $selectedIds

    const changeDate = (date) => {
        setDate(date.valueOf())
    }

    useEffect(() => {
        let timeout = null

        timeout = setTimeout(() => {
            if (stationId && !subSectorId && !$selectedIds.mounted) {
                selectEvent({showForm: 'station'})
                selectEvent({mounted: true})
            }
        }, 300)

        return () => {
            clearTimeout(timeout)
            timeout = null
        }
    }, [stationId, subSectorId, $selectedIds.mounted])

    useEffect(() => {
        let timeout = null

        timeout = setTimeout(() => {
            if (sectorId && !stationId && !subSectorId) {
                selectEvent({mounted: true})
            }
        }, 300)

        return () => {
            clearTimeout(timeout)
            timeout = null
        }
    }, [stationId, subSectorId, sectorId, $selectedIds.mounted])

    const formHandleSelect = (type) => {
        const {station, category, attribute, sector} = $selectedIds

        if (type !== 'common' && sectorId) {
            const ids = stringToArray(sectorId, ',')
            if ($selectedIds.sector[ids[0]]) {
                const allData = {
                    station,
                    station_sector: {},
                    sub_sector: {},
                    category,
                    attribute,
                    sector: {[ids[0]]: $selectedIds.sector[ids[0]]}
                }
                selectEvent({
                    ...$selectedIds,
                    startValue: '',
                    endValue: '',
                    type: null,
                    ...allData,
                    showForm: type,
                    sector: {[ids[0]]: $selectedIds.sector[ids[0]]}
                })
                const queryParams = generateQueryParams(allData)
                push({
                    pathname: '/',
                    search: `info&${organizationId
                        ? `${URL_ID.ORGANIZATION}=${organizationId}`
                        : ''}${queryParams ? `&${queryParams}` : ''}`
                })
            }
        } else {
            const allData = {
                station: {},
                station_sector: {},
                sub_sector: {},
                category,
                attribute,
                sector
            }
            selectEvent({
                ...$selectedIds,
                ...allData,
                startValue: '',
                endValue: '',
                showDetail: false,
                showForm: type,
                type: null
            })
            const queryParams = generateQueryParams(allData)
            push({
                pathname: '/',
                search: `info&${organizationId
                    ? `${URL_ID.ORGANIZATION}=${organizationId}`
                    : ''}${queryParams ? `&${queryParams}` : ''}`
            })
        }
    }

    const commonReport = useCallback(() => {
        let sectorData, attributeData
        const selectedSubSector = Object.values(sub_sector)
        const subSectorList = objToArray($reportSubSectorList.data)
        const selectedAttribute = Object.values(attribute)
        const attributeList = objToArray($reportAttributeList.data)
        const selectedCategories = Object.keys(category)
        const selectedSectors = Object.keys(sector)

        if (selectedSectors.length > 0 && selectedCategories.length > 0) {
            if (selectedSubSector.length > 0) {
                sectorData = getInfo(selectedSubSector, 'sector')
            } else {
                sectorData = getInfo(getMatches(selectedSectors, subSectorList), 'sector')
            }

            if (selectedAttribute.length > 0) {
                attributeData = getInfo(selectedAttribute)
            } else {
                attributeData = getInfo(getMatches(selectedCategories, attributeList))
            }

            if (sectorData && attributeData) {
                const commonReportParams = {
                    categoryList: Object.values(category),
                    data: {
                        attributeIds: attributeData.ids,
                        fromMeter: selectedSubSector.length === 1 ? Number(startValue) : sectorData.from,
                        sectorIds: sectorData.ids,
                        toMeter: selectedSubSector.length === 1 ? Number(endValue) : sectorData.to,
                        ts: date.getTime()
                    }
                }
                selectEvent({type: 'common'})
                summaryMount(commonReportParams)
            }
        }

    }, [
        date,
        sub_sector,
        $reportSubSectorList.data,
        attribute,
        $reportAttributeList,
        sector,
        category,
        startValue,
        endValue
    ])

    const commonDetailReport = useCallback((type) => {
        let attributeData
        const selectedSubSector = type === 'subSector' ? Object.values(sub_sector) : Object.values(station_sector)
        const selectedAttribute = Object.values(attribute)
        const attributeList = objToArray($reportAttributeList.data)
        const categoryList = Object.values(category)
        if (Object.keys(sector).length > 0 && Object.keys(category).length > 0 && selectedSubSector.length === 1) {
            generateDetailThead({endValue, startValue})

            if (selectedAttribute.length > 0) {
                attributeData = getInfo(selectedAttribute)
                generateDetailTbody({endValue, startValue, attributeList: selectedAttribute, categoryList})
            } else {
                attributeData = getInfo(attributeList)
                generateDetailTbody({endValue, startValue, attributeList, categoryList})
            }

            initDetailReport(categoryList)
            selectEvent({type: 'detail'})
            detailForceLoading(true)
            const attrs = attributeData.ids
            for (let i = 0; i < attrs.length; i++) {
                const data = {
                    attributeId: attrs[i],
                    sectorId: selectedSubSector[0].id,
                    from: startValue,
                    to: endValue,
                    helper: {
                        params: {
                            ts: new Date(date).getTime(),
                        },
                    }
                }

                if(i + 1 === attrs.length) {
                    detailForceLoading(false)
                }
                summaryDetailMount(data)
            }
        }
    }, [
        endValue,
        startValue,
        $reportAttributeList.data,
        category,
        attribute,
        sector,
        date,
        sub_sector,
        station_sector,
    ])


    const onSubmit = useCallback(() => {
        if (showForm === 'common') {
            if (showDetail) {
                commonDetailReport('subSector')
            } else {
                commonReport()
            }
        } else if (showForm === 'station-sector') {
            if (showDetail) {
                commonDetailReport('stationSector')
            }
        }
    }, [showForm, showDetail, commonReport, commonDetailReport])

    return {
        date,
        changeDate,
        formHandleSelect,
        onSubmit
    }
}