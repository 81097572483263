import React from 'react'
import {AllOrganizationInfo, OrganizationInfo} from "../moleculas";
import {useUrlParams} from "../../../hooks/common";
import {useHistory} from 'react-router-dom'
import {useStore} from "effector-react";
import {$userModel} from "../../../models/user-model";

export const ReportInfo = () => {
  const {location} = useHistory()
  const {organizationId} = useUrlParams(location)
  const {$userRole} = useStore($userModel)

  return (
    <>
      {
        organizationId
          ? <OrganizationInfo/>
          : $userRole ? <AllOrganizationInfo/> : ''
      }
    </>
  )
}