import {combine, createStore} from "effector";
import {
    fetchContextInfo,
    fetchOrganizationList,
    fetchOrganizationSectorsList,
    fetchOrgList,
    fetchSectorInfo,
    fetchMultipleSubSector,
    fetchOrganizationListUser
} from "./effects";
import {
    createSectorLength,
    getSectorsFromOrganization,
    nextEvent,
    previousEvent,
    userOrganizations,
    multipleSubSectoFromPermission
} from "./events";
import {convertTo} from '../../helpers';

const {Math} = convertTo();


export const $organizationsList = createStore({loading: false, data: [], result: [], error: false})
    .on(fetchOrganizationList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchOrganizationList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data.data.map((item, index) => ({
                    label: item.name,
                    id: item.id,
                    value: item.id,
                    key: index
                })),
                result: response.result.data.data,
                error: false
            };
        }
    })
    .on(fetchOrganizationListUser.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchOrganizationListUser.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            const data = response.result.data.data.filter(item => item.superior.username === response.params.username).map((item, index) => ({
                label: item.name,
                id: item.id,
                value: item.id,
                key: index
            }));
            return {
                ...params,
                data: data,
                result: response.result.data.data,
                error: false
            };
        }
    })
    .on(userOrganizations, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.map((item, index) => ({
                    label: item.name,
                    id: item.id,
                    value: item.id,
                    key: index
                })),
                result: response,
                error: false
            };
        }
    })
    .on(fetchContextInfo.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchContextInfo.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data.organizations.map((item, index) => ({
                    label: item.organization.name,
                    id: item.organization.id,
                    value: item.organization.id,
                    key: index
                })),
                result: response.result.data.organizations,
                error: false
            };
        }
    });

export const $organizationsSectorsList = createStore({loading: false, data: [], error: false,})
    .on(fetchOrganizationSectorsList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchOrganizationSectorsList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data.map((item, index) => ({
                    label: item.name,
                    id: item.id,
                    value: item.id,
                    key: index,
                    length: item.length,
                    startPoint: item.startPoint,
                    countdownPoint: item.countdownPoint
                })),
                result: response.result.data,
                error: false
            };
        }
    })
    .on(getSectorsFromOrganization, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            const dataFilter = response.reduce((acc, n) => {
                const i = acc.findIndex(m => m.sector.id === n.sector.id);
                if (!~i || !acc[i].checked) {
                    acc.push(n);
                    if (~i) {
                        acc.splice(i, 1);
                    }
                }
                return acc;
            }, []);
            return {
                ...params,
                data: dataFilter.map((item, index) => ({
                        label: item.sector.name,
                        name: item.sector.name,
                        id: item.sector.id,
                        value: item.sector.id,
                        key: index,
                        length: item.sector.length,
                        startPoint: item.sector.startPoint,
                        countdownPoint: item.sector.countdownPoint,
                        subSectors: response.filter(orgSector => orgSector.sector && orgSector.subSector && orgSector.sector.id === item.sector.id).map((filtered, index) => ({
                            label: filtered.subSector.name,
                            name: filtered.subSector.name,
                            id: filtered.subSector.id,
                            value: filtered.subSector.id,
                            length: filtered.subSector.length,
                            parent: filtered.subSector.parent,
                            parentId: filtered.subSector.parent.id,
                            startPoint: filtered.subSector.startPoint,
                            countdownPoint: filtered.subSector.countdownPoint,
                            station: filtered.subSector.station,
                            secondaryStage: filtered.subSector.secondaryStage,
                            key: index
                        })),
                    })
                ),
                result: response,
                error: false
            };
        }
    })
    .on(fetchSectorInfo.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchSectorInfo.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: params.data.map(item =>
                    item.id !== response.params.id ?
                        item :
                        {
                            ...item,
                            subSectors: response.result.data.data.map((item, index) =>
                                ({
                                    label: item.name,
                                    id: item.id,
                                    value: item.id,
                                    key: index,
                                    station: item.station,
                                    secondaryStage: item.secondaryStage,
                                })
                            ),
                            resultSubs: response.result.data.data
                        }),
                result: params.result.map(item =>
                    item.id !== response.params.id ?
                        item :
                        {
                            ...item,
                            subSector: response.result.data.data.map((subSector, index) =>
                                ({
                                    label: subSector.name,
                                    id: subSector.id,
                                    value: subSector.id,
                                    length: subSector.length,
                                    parentId: subSector.parent.id,
                                    startPoint: subSector.startPoint,
                                    countdownPoint: subSector.countdownPoint,
                                    key: index,
                                })
                            ),
                            resultSubs: response.result.data.data
                        }),
                error: false
            };
        }
    });

export const $sectorLength = createStore({loading: false, data: [], error: false})
    .on(createSectorLength, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            const data = [];
            const lengthNumber = Math.floor10(response.length / 1000) > 44 ? 44 : Math.floor10(response.length / 1000);
            for (let i = response.start; i <= (Number(response.start) + Number(lengthNumber)); i++) {
                data.push({
                    name: i,
                    key: i,
                    length: Math.floor10(response.length / 1000)
                })
            }
            return {
                ...params,
                data: data,
                result: {...response, lengthKilo: Math.floor10(response.length / 1000)},
                error: false
            };
        }
    })
    .on(nextEvent, (params) => {
        const data = [];
        if (params.data.length > 43) {
            const start = params.data[44].name;
            const length = ((params.result.start + params.result.lengthKilo) - start) < 9 ? params.result.start + params.result.lengthKilo : start + 9;

            for (let i = 9; i < params.data.length; i++) {
                data.push(params.data[i]);
            }
            for (let i = start; i < length; i++) {
                data.push({
                    name: i,
                    key: i,
                })
            }
        } else {
            data.push(...params.data)
        }

        return {
            ...params,
            data: data,
        };

    })
    .on(previousEvent, (params) => {
        const data = [];
        if (params.result.start < params.data[0].name && (params.data[0].name - params.result.start) > 44) {
            const start = params.data[0].name - 9;
            for (let i = start; i < start + 44; i++) {
                data.push({
                    name: i,
                    key: i,
                })
            }
        } else if (params.result.start < params.data[0].name && (params.data[0].name - params.result.start) < 44 && (params.data[0].name - params.result.start) > 0) {
            const start = params.result.start;
            for (let i = start; i < start + 44; i++) {
                data.push({
                    name: i,
                    key: i,
                })
            }
            data.push(data)
        } else {
            data.push(...params.data)
        }

        return {
            ...params,
            data: data,
        };
    });

const $orgList = createStore({loading: true, data: [], filtered: [], error: false})
    .on(fetchOrgList.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchOrgList.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            return {
                ...state,
                error: false,
                data: response.result.data.data,
                filtered: response.result.data.data.map(item => ({
                    value: item.id,
                    label: item.name
                })),
            }
        }
    });

const $multipleSubSectorList = createStore({loading: false, data: [], orgId: null, error: false})
    .on(fetchMultipleSubSector.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        }
    })
    .on(fetchMultipleSubSector.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],

            }
        } else {

            const {data, orgId} = params
            let id = ''
            let newData = null

            const resData = response.result.data.data.map(item => ({
                label: item.name,
                value: item.id,
                length: item.length,
                parentId: item.parent.id,
                countdownPoint: item.countdownPoint,
                startPoint: item.startPoint
            }))

            if (orgId === null || orgId !== response.params.orgId) {
                id = response.params.orgId
                newData = resData
            }

            if (orgId !== null) {
                if (orgId === response.params.orgId) {
                    id = orgId
                    newData = [...data, ...resData]
                    console.log(orgId)
                }
            }

            return {
                ...params,
                error: false,
                data: newData,
                orgId: id
            }
        }
    })
    .on(multipleSubSectoFromPermission, (params, arr) => {
        return {
            ...params,
            data: arr
        }
    })

export const $organization = combine({
    $organizationsList,
    $organizationsSectorsList,
    $sectorLength,
    $orgList,
    $multipleSubSectorList
});
